import { FetchMediaError, fetchMediaWrapped } from 'fetch-media';
import { useEffect, useRef } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import type { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiTrack = ReadonlyDeep<{
  track: {
    _links: {
      self: {
        href: string;
      };

      artist?: {
        href: string;
        name: string;
      };

      cover?: {
        href: string;
        templated: true;
      };

      poster?: {
        href: string;
        templated: true;
      };

      wave?: {
        href: string;
        duration: number;
        templated: true;
      };

      stream?: {
        href: string;
        duration: number;
        templated: true;
      };
    };

    name: string;
    lyrics: string;
    transcript: { timestamp: string; timestamp_end?: string; lyrics: string }[];
  };
}>;

export type ApiTrackCoverImageVariants =
  | 'size_1x'
  | 'size_2x'
  | 'size_3x'
  | 'size_4x';

export type ApiTrackPosterImageVariants =
  | 'portrait_1x'
  | 'portrait_2x'
  | 'portrait_3x'
  | 'portrait_4x';

export type ApiTrackWaveVariants = '96b' | '128b' | '160b' | '192b';
export type ApiTrackStreamVariants = '160b-1080:1920';

export function useTrack(
  url: string | null | undefined,
  {
    enabled = true,
    onError,
    ...options
  }: UseQueryOptions<ApiTrack, FetchMediaError | Error> = {}
) {
  const urlRef = useRef(url);
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  useEffect(() => {
    urlRef.current = url;
    // Alert.alert(`tracks url: ${url}`);
  }, [url]);

  return useQuery<ApiTrack, FetchMediaError | Error>(
    ['tracks', locale, url] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const result = await fetchMediaWrapped(urlRef.current!, {
        headers: {
          accept: ['application/vnd.soundersmusic.track.v3+json'].join(', '),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (!result.ok()) {
        throw result.unwrap();
      }

      const contentType = result.response.headers.get('Content-type');
      if (!contentType) {
        throw new Error('Expected Content-Type to be set');
      }

      const body = result.unwrap();

      if (typeof body === 'string' || !body) {
        throw new Error(`Expected track, but got a ${typeof body}`);
      }

      if (
        typeof body === 'object' &&
        !Object.prototype.hasOwnProperty.call(body, 'track')
      ) {
        throw new Error(
          `Expected track, but got ${Object.keys(body).join(', ')}`
        );
      }

      return body as ApiTrack;
    },
    {
      enabled: Boolean(url && token && enabled),

      async onError(error) {
        console.error({ error });
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            await logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
