import { useRef, useState } from 'react';

import {
  RouteProp,
  useIsFocused,
  useLinkProps,
  useRoute,
} from '@react-navigation/native';
import {
  Image,
  Platform,
  ScrollView,
  View,
  useWindowDimensions,
  FlatList,
  Linking,
} from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  Button,
  Chip,
  Portal,
  Text,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useGoBack } from '../components/BackHeader';
import { defineTranslations, i18n } from '../locale';
import {
  BLACK,
  GREY,
  LIGHT_BLUEISH_GREY,
  PRIMARY_DARK,
  SOFT_PURPLE,
  TURQUOISE,
  WHITE,
  YELLOW,
} from '../theming';
import { RootStackParamList } from '../types';
import { AnimatedImage } from '../components/AnimatedImage';
import { useLerpWidth } from '../utils/useLerpWidth';
import { IS_DEBUG, IS_PREVIEW } from '../debug';
import { useTimeline } from './useTimeline';
import {
  Button as ButtonData,
  Participant,
  Phase,
  Timeline,
  Countdown as CountdownData,
  Property,
  Winner,
} from './TimelineTypes';

const MAX_HEADER_WIDTH = 850;
const MAX_COLUMN_WIDTH = 346 * 1.2 + 16;
const BACKGROUND_CENTER_OFFSET = 50;
const IMAGE_WIDTH = 416;
const ARTIST_IMAGE_WIDTH = 128;

const HOUR = 1000 * 3600;
const DAY = HOUR * 24;
const TIMESTAMP_OFFSET = 0; //1 * (DAY * 62) + HOUR * 12 + 60000 * 36;

defineTranslations({
  en: {
    app: {
      timeline: {
        title: 'Timeline',
        description: 'Timeline of the Mentos Grote Prijs van Nederland.',
        live: 'Live',
        finished: 'Finished',
        countdown: {
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes',
        },
      },
    },
  },

  nl: {
    app: {
      timeline: {
        title: 'Tijdlijn',
        description: 'De tijdlijn van de Mentos Grote Prijs van Nederland.',
        live: 'Live',
        finished: 'Afgelopen',
        countdown: {
          days: 'Dagen',
          hours: 'Uren',
          minutes: 'Minuten',
        },
      },
    },
  },
});

export function TimelineScreen() {
  const { top, bottom } = useSafeAreaInsets();

  const focused = useIsFocused();
  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Timeline'>>().params ?? {};

  // Load timeline data
  const { data, isLoading, error, refetch } = useTimeline({ enabled: focused });
  const timeline = isLoading || !data ? null : data.data.timeline;

  const timestamp =
    useInaccurateTimestamp({ every: 30 * 1000 }) + TIMESTAMP_OFFSET;

  const onGoBack = useGoBack();

  const textColor = WHITE;

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  const screenWidth = useWindowDimensions().width;

  const activePhase = timeline ? getActivePhase(timeline, timestamp) : null;
  const activeIndex = activePhase
    ? timeline?.phases.indexOf(activePhase)
    : null;

  const timelineRef = useRef<FlatList | null>(null);

  return (
    <Portal.Host>
      <View style={{ flex: 1, position: 'relative', width: '100%' }}>
        {/*Main body */}

        <ScrollView
          style={{
            width: '100%',
            height: '100%',
            maxHeight: Platform.select({ web: '100vh', default: '100%' }),
            backgroundColor: SOFT_PURPLE,
          }}
          contentContainerStyle={{
            width: '100%',
            minHeight: '100%',
            alignSelf: 'center',
            overflow: 'hidden',
            paddingTop: top,
            paddingBottom: bottom,
          }}
        >
          <BackgroundImage top={top - 65} />
          <LogoImage alt="Grote Prijs van Nederland logo" top={top + 10} />
          <View
            style={{
              marginHorizontal: 'auto',
              width: '100%',
              flex: 1,
              paddingTop: 50,
            }}
          >
            {!timeline ? (
              <ActivityIndicator
                size="large"
                color={WHITE}
                style={{ marginTop: 64 }}
              />
            ) : (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'flex-start',
                  width: '100%',

                  alignSelf: 'center',

                  paddingTop: 50,
                  paddingBottom: 0,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: 16 + lerpWidth * 8,
                    maxWidth: MAX_COLUMN_WIDTH,
                    marginHorizontal: 'auto',
                  }}
                >
                  {/* Title */}
                  <Text
                    variant="titleLarge"
                    style={{
                      color: textColor,
                      textAlign: 'left',

                      fontSize: 24 + lerpWidth * 6,
                      lineHeight: 30 + lerpWidth * 4,
                      fontWeight: '700',
                      marginBottom: 10,

                      textShadowColor: BLACK + '4A',
                      textShadowOffset: { width: 1, height: 1 },
                      textShadowRadius: 0,
                    }}
                  >
                    {timeline.title}
                  </Text>

                  {/* Summary */}
                  <Text
                    style={{
                      color: textColor,
                      textAlign: 'left',

                      fontSize: 14 + lerpWidth * 2,
                      lineHeight: 21 + lerpWidth * 3,
                      marginBottom: 12 + 0.2 * 20,

                      textShadowColor: BLACK + '4A',
                      textShadowOffset: { width: 1, height: 1 },
                      textShadowRadius: 0,
                    }}
                  >
                    {timeline.summary}
                  </Text>
                </View>

                {/* Timeline */}
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  {/* Line list */}
                  <View
                    style={{
                      borderTopColor: WHITE + '66',
                      borderTopWidth: 4,
                      width: '100%',
                      position: 'absolute',
                      top: 14,
                      minHeight: 400,
                    }}
                  />
                  {/* Phase scroller */}
                  {/* https://reactnative.dev/docs/flatlist */}
                  <FlatList
                    ref={timelineRef}
                    nativeID="timeline"
                    horizontal
                    data={timeline.phases}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item, index }) => (
                      <PhaseTile
                        phase={item}
                        active={index === activeIndex}
                        live={
                          index === activeIndex &&
                          new Date(item.startAt).getTime() <= timestamp
                        }
                        finished={new Date(item.endAt).getTime() < timestamp}
                        maxWidth={screenWidth * 0.8}
                      />
                    )}
                    contentContainerStyle={{
                      paddingRight: 16 + lerpWidth * 8,
                      paddingBottom: 16 + lerpWidth * 4,
                    }}
                    extraData={activeIndex}
                    initialScrollIndex={activeIndex}
                    onScrollToIndexFailed={({ index }) => {
                      // Continue scrolling
                      timelineRef.current?.scrollToOffset({
                        offset: index * 270,
                        animated: true,
                      });
                      // Scroll to index after a short wait
                      const wait = new Promise((resolve) =>
                        setTimeout(resolve, 500)
                      );
                      wait.then(() => {
                        timelineRef.current?.scrollToIndex({
                          index,
                          animated: true,
                        });
                      });
                    }}
                  />
                </View>
              </View>
            )}
          </View>
        </ScrollView>

        {/* Header */}
        <View
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              maxWidth: 600,
              marginHorizontal: 'auto',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 15,
              paddingTop: top + 12,
            }}
          >
            {/* Back button */}
            <Appbar.BackAction
              size={24}
              color={PRIMARY_DARK}
              onPress={onGoBack}
              style={{
                borderRadius: 54 / 2,
                backgroundColor: WHITE,
                width: 45,
                height: 45,
                marginLeft: -2,
              }}
            />
          </View>
        </View>
      </View>
    </Portal.Host>
  );
}

function PhaseTile({
  phase,
  active,
  finished,
  live,

  maxWidth,
}: {
  phase: Phase;
  active: boolean;
  live: boolean;
  finished: boolean;
  maxWidth: number;
}) {
  const now = new Date().getTime() + TIMESTAMP_OFFSET;

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  const backgroundColor = active
    ? BLACK
    : finished
    ? LIGHT_BLUEISH_GREY
    : WHITE;
  const textColor = active ? WHITE : BLACK;

  const dotSize = active ? 32 : 24;

  const countdowns =
    phase.countdowns && phase.countdowns.length > 0
      ? phase.countdowns.filter(
          (countdown) =>
            new Date(countdown.startAt).getTime() < now &&
            new Date(countdown.endAt).getTime() > now
        )
      : null;

  const participants =
    !!phase.participants &&
    phase.participants.length > 0 &&
    phase.participants[0].visible
      ? phase.participants[0]
      : null;

  const winners =
    !!phase.winners && phase.winners.length === 1 && phase.winners[0];

  const buttons = phase.buttons
    ? phase.buttons.filter(
        (button) =>
          (!button.startAt || new Date(button.startAt).getTime() < now) &&
          (!button.endAt || new Date(button.endAt).getTime() > now)
      )
    : null;

  return (
    <View
      style={{
        width: 250 + lerpWidth * 20,
        maxWidth,
        marginLeft: 16 + lerpWidth * 8,
        marginBottom: 10,
      }}
    >
      {/* Dot */}
      <View
        style={{
          width: dotSize,
          height: dotSize,
          backgroundColor,
          borderRadius: dotSize,
          borderColor: textColor,
          borderWidth: 8,
          marginTop: active ? 0 : 4,
          marginBottom: active ? 10 : 30,
        }}
      />
      {/* Line */}
      <View
        style={{
          width: active ? 4 : 2,
          height: active ? 30 : 50,
          backgroundColor,
          position: 'absolute',
          left: active ? 14 : 11,
          top: 15,
        }}
      />

      {/* Card */}
      <View
        style={{
          backgroundColor,
          borderRadius: 8,
          paddingHorizontal: 16 + lerpWidth * 8,
          paddingTop: 16 + lerpWidth * 8,
        }}
      >
        {/* Countdowns */}
        {countdowns &&
          countdowns.map((countdown) => (
            <Countdown
              key={countdown.id}
              countdown={countdown}
              active={active}
            />
          ))}

        {/* Indicators */}
        {live || finished ? (
          <View style={{ flexDirection: 'row', marginBottom: 16 }}>
            {/* Live */}
            {live ? (
              <Chip
                style={{
                  backgroundColor: TURQUOISE,
                  borderRadius: 100,
                }}
              >
                <Text style={{ color: BLACK, fontWeight: '700' }}>
                  {i18n.translate('app.timeline.live')}
                </Text>
              </Chip>
            ) : null}

            {/* Finished */}
            {finished ? (
              <Chip
                style={{
                  backgroundColor: GREY,
                  borderRadius: 100,
                }}
              >
                <Text style={{ color: WHITE, fontWeight: '700' }}>
                  {i18n.translate('app.timeline.finished')}
                </Text>
              </Chip>
            ) : null}
          </View>
        ) : null}

        {/* Title */}
        {!!phase.title && (
          <Text
            variant="titleLarge"
            numberOfLines={4}
            style={{
              color: textColor,
              textAlign: 'left',

              fontSize: 24 + lerpWidth * 6,
              lineHeight: 30 + lerpWidth * 4,
              marginBottom: 7 + lerpWidth * 3,
              fontWeight: '700',
            }}
          >
            {phase.title}
          </Text>
        )}

        {/* Subtitle */}
        {!!phase.subtitle && (
          <Text
            style={{
              color: textColor,
              textAlign: 'left',
              fontSize: 12 + lerpWidth * 2,
              lineHeight: 21 + lerpWidth * 3,
              marginBottom: 16 + lerpWidth * 4,
            }}
          >
            {phase.subtitle.toUpperCase()}
          </Text>
        )}

        {/* Image 1 */}
        {!!phase.image1 && (
          <AnimatedImage
            accessibilityLabel={phase.image1.alt}
            fadeDuration={1000}
            animateOpacity
            resizeMode="cover"
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              marginBottom: 16 + lerpWidth * 4,
              borderRadius: 5 + lerpWidth * 3,
              backgroundColor: WHITE + 'aa',
              aspectRatio: phase.image1.width / phase.image1.height,
            }}
            source={{
              uri: phase.image1.url,
              width: IMAGE_WIDTH,
              height: Math.round(
                IMAGE_WIDTH * (phase.image1.height / phase.image1.width)
              ),
            }}
          />
        )}

        {/* PropertyList */}
        {phase.propertyList && phase.propertyList.length > 0 && (
          <View style={{ marginBottom: 8 + lerpWidth * 4 }}>
            {phase.propertyList.map((prop) => (
              <PropRow key={prop.id} prop={prop} active={active} />
            ))}
          </View>
        )}

        {/* Description */}
        {!!phase.description && (
          <Text
            style={{
              color: textColor,
              textAlign: 'left',
              fontSize: 14 + lerpWidth * 2,
              lineHeight: 21 + lerpWidth * 3,
              marginBottom: 18 + lerpWidth * 4,
            }}
          >
            {phase.description}
          </Text>
        )}

        {/* Image 2 */}
        {!!phase.image2 && (
          <AnimatedImage
            accessibilityLabel={phase.image2.alt}
            fadeDuration={1000}
            animateOpacity
            resizeMode="cover"
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              marginBottom: 18 + lerpWidth * 4,
              borderRadius: 5 + lerpWidth * 3,
              backgroundColor: WHITE + 'aa',
              aspectRatio: phase.image2.width / phase.image2.height,
            }}
            source={{
              uri: phase.image2.url,
              width: IMAGE_WIDTH,
              height: Math.round(
                IMAGE_WIDTH * (phase.image2.height / phase.image2.width)
              ),
            }}
          />
        )}

        {/* Participants */}
        {!!participants && (
          <Participants participants={participants} active={active} />
        )}

        {/* Winners */}
        {!!winners && <Winners winners={winners} active={active} />}

        {/* Buttons */}
        {buttons && buttons.length > 0 && (
          <View style={{ marginBottom: 8 + lerpWidth * 8 }}>
            {buttons.map((button) => (
              <IconButton key={button.id} button={button} />
            ))}
          </View>
        )}
      </View>
    </View>
  );
}

function PropRow({ prop, active }: { prop: Property; active: boolean }) {
  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  const iconSource = active ? Icons.white[prop.icon] : Icons.black[prop.icon];
  const textColor = active ? WHITE : BLACK;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        marginBottom: 6 + lerpWidth * 2,
      }}
    >
      {/* Icon */}
      {!!iconSource && (
        <Image
          source={iconSource}
          resizeMode="cover"
          style={{
            width: 24,
            height: 24,
            opacity: 0.6,
            marginRight: 10,
          }}
        />
      )}

      {/* Label */}
      <Text
        style={{
          color: textColor,
          textAlign: 'left',
          maxWidth: Platform.OS === 'web' ? '100%' : '85%',

          fontSize: 14 + lerpWidth * 2,
          lineHeight: 21 + lerpWidth * 3,
        }}
      >
        {prop.title}
      </Text>
    </View>
  );
}

function Participants({
  participants,
  active,
}: {
  participants: Participant;
  active: boolean;
}) {
  const lerpWidth = useLerpWidth([315, 0], [375, 1]);
  const textColor = active ? WHITE : BLACK;

  return (
    <View
      style={{
        borderRadius: 8,
        padding: 12 + lerpWidth * 4,
        backgroundColor: active ? WHITE + '25' : BLACK + '16',
        marginBottom: 16 + lerpWidth * 8,
      }}
    >
      <View
        style={{
          marginVertical: 4 + lerpWidth * 4,
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Infinity */}
        {participants.fromInfinity && (
          <Image
            source={require('../../assets/icons/icon-infinity.png')}
            resizeMode="cover"
            style={{
              width: 51,
              height: 22,
            }}
          />
        )}
        {/* From */}
        {participants.from !== undefined && (
          <Text
            variant="titleLarge"
            style={{
              opacity: 0.5,
              color: textColor,
              fontSize: 28 + lerpWidth * 4,
              lineHeight: 28 + lerpWidth * 4,
              fontWeight: '700',
            }}
          >
            {participants.from}
          </Text>
        )}
        {/* Arrow */}
        <Image
          source={active ? Icons.white.arrowRight : Icons.black.arrowRight}
          resizeMode="cover"
          style={{
            opacity: 0.5,
            width: 24,
            height: 24,
            marginHorizontal: 6 + lerpWidth * 2,
            marginTop: Platform.OS === 'web' ? 0 : -4,
          }}
        />
        {/* To */}
        <Text
          variant="titleLarge"
          style={{
            color: textColor,
            fontSize: 28 + lerpWidth * 4,
            lineHeight: 28 + lerpWidth * 4,
            fontWeight: '700',
          }}
        >
          {participants.to}
        </Text>
      </View>
      {!!participants.description && (
        <Text
          style={{
            textAlign: 'center',
            color: textColor,
            fontSize: 11 + lerpWidth * 1,
            lineHeight: 16 + lerpWidth * 2,
            marginTop: 8 + lerpWidth * 3,
          }}
        >
          {participants.description}
        </Text>
      )}
    </View>
  );
}

function Winners({
  winners,
  active,
  maxActs = 2,
}: {
  winners: Winner;
  active: boolean;
  maxActs?: number;
}) {
  const lerpWidth = useLerpWidth([315, 0], [375, 1]);
  const textColor = winners.yellow ? BLACK : active ? WHITE : BLACK;

  const [showAll, setShowAll] = useState(winners.artists.length <= maxActs + 1);

  const visibleArtists = showAll
    ? winners.artists
    : winners.artists.slice(0, maxActs);

  const imageWidth = 128;

  return (
    <View
      style={{
        borderRadius: 8,
        paddingTop: 12 + lerpWidth * 4,
        paddingBottom: 4 + lerpWidth * 2,
        paddingHorizontal: 12 + lerpWidth * 4,
        backgroundColor: winners.yellow
          ? YELLOW
          : active
          ? WHITE + '25'
          : BLACK + '16',
        marginBottom: 16 + lerpWidth * 8,
      }}
    >
      {!!winners.title && (
        <Text
          style={{
            textAlign: 'center',
            color: textColor,
            fontSize: 11 + lerpWidth * 1,
            lineHeight: 16 + lerpWidth * 2,
            marginBottom: 8 + lerpWidth * 3,
          }}
        >
          {winners.title}
        </Text>
      )}

      {visibleArtists.map((artist) => (
        <View
          key={artist.id}
          style={{
            backgroundColor: WHITE,
            borderRadius: 8,
            marginBottom: 8 + lerpWidth * 3,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            accessibilityLabel={artist.image.alt}
            resizeMode="cover"
            style={{
              width: 64,
              height: 48,
              marginRight: 6 + lerpWidth * 2,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            }}
            source={{
              uri: artist.image.url,
              width: ARTIST_IMAGE_WIDTH,
              height: Math.round(
                ARTIST_IMAGE_WIDTH * (artist.image.height / artist.image.width)
              ),
            }}
          />

          <Text
            numberOfLines={2}
            style={{
              fontWeight: '700',
              color: BLACK,
              fontSize: 11 + lerpWidth * 1,
              lineHeight: 16 + lerpWidth * 2,
            }}
          >
            {artist.name}
          </Text>
        </View>
      ))}

      {!showAll && (
        <Button
          onPress={() => setShowAll(true)}
          style={{
            backgroundColor: LIGHT_BLUEISH_GREY,
            borderRadius: 8,
          }}
        >
          <Text style={{ color: BLACK, fontWeight: '700' }}>
            + {winners.artists.length - maxActs} acts
          </Text>
        </Button>
      )}
    </View>
  );
}

function IconButton({ button }: { button: ButtonData }) {
  const isAbsolute = button.href.startsWith('http');

  if (isAbsolute) {
    // Rewrite to https
    button.href = button.href.replace(/^http:/, 'https:');
  } else {
    // Powerpuff rewrites for dev/prod
    if (IS_DEBUG || IS_PREVIEW) {
      button.href = button.href.replace('!buttercup!', '!bubbles!');
    } else {
      button.href = button.href.replace('!bubbles!', '!buttercup!');
    }
  }

  const linkProps = useLinkProps({
    to: button.href,
  });

  // External link
  if (isAbsolute) {
    linkProps.href = '';
    linkProps.onPress = () => Linking.openURL(button.href);
  }

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  const buttonStyle = ButtonColor[button.color] || {
    buttonColor: BLACK,
    textColor: WHITE,
  };

  const iconSource =
    Icons[buttonStyle.textColor === WHITE ? 'white' : 'black'][button.icon];

  return (
    <Button
      {...linkProps}
      {...buttonStyle}
      mode="contained"
      icon={() =>
        iconSource ? (
          <Image
            source={iconSource}
            resizeMode="cover"
            style={{
              width: 24,
              height: 24,
            }}
          />
        ) : null
      }
      style={{
        flexGrow: 1,
        marginRight: 5,
        marginBottom: 8 + lerpWidth * 8,
        borderRadius: 25,
      }}
      labelStyle={{ fontWeight: '700', fontSize: 12 + lerpWidth * 2 }}
      contentStyle={{
        height: 44,
        flexDirection:
          button.alignIcon === 'align-icon-right' ? 'row-reverse' : 'row',
      }}
    >
      {button.title}
    </Button>
  );
}

const MS_PER_DAY = 24 * 60 * 60 * 1000;
const MS_PER_HOUR = 60 * 60 * 1000;
const MS_PER_MINUTE = 60 * 1000;

function Countdown({
  countdown,
  active,
}: {
  countdown: CountdownData;
  active: boolean;
}) {
  const interval = useRef(30);
  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  const timestamp =
    useInaccurateTimestamp({ every: interval.current * 1000 }) +
    TIMESTAMP_OFFSET;

  const started =
    !countdown.startAt || new Date(countdown.startAt).getTime() < timestamp;
  const finished =
    !!countdown.endAt && new Date(countdown.endAt).getTime() < timestamp;

  if (!started || finished) return null;

  const textColor = active ? WHITE : BLACK;
  const borderColor = active ? WHITE + '40' : BLACK + '16';

  const timeDiff = Math.max(0, new Date(countdown.endAt).getTime() - timestamp);
  const days = Math.floor(timeDiff / MS_PER_DAY);
  const hours = Math.floor((timeDiff % MS_PER_DAY) / MS_PER_HOUR);
  const minutes = Math.floor((timeDiff % MS_PER_HOUR) / MS_PER_MINUTE);

  if (days + hours === 0 && minutes <= 2) interval.current = 5;

  return (
    <View
      style={{
        marginBottom: 16 + lerpWidth * 8,
        borderRadius: 8,
        borderWidth: 2,
        paddingHorizontal: 20 + lerpWidth * 4,
        paddingTop: 10,
        paddingBottom: 16,
        borderColor,
      }}
    >
      {!!countdown.title && (
        <Text
          style={{
            textAlign: 'center',
            color: textColor,
            fontSize: 13 + lerpWidth * 1,
            lineHeight: 16 + lerpWidth * 2,
            marginBottom: 10 + lerpWidth * 2,
          }}
        >
          {countdown.title}
        </Text>
      )}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TimeUnit
          title={i18n.translate('app.timeline.countdown.days')}
          number={days}
          active={active}
        />
        <TimeUnit
          title={i18n.translate('app.timeline.countdown.hours')}
          number={hours}
          active={active}
        />
        <TimeUnit
          title={i18n.translate('app.timeline.countdown.minutes')}
          number={minutes}
          active={active}
        />
      </View>
    </View>
  );
}

function TimeUnit({
  title,
  number,
  active,
}: {
  title: string;
  number: number;
  active: boolean;
}) {
  const lerpWidth = useLerpWidth([315, 0], [375, 1]);
  const textColor = active ? WHITE : BLACK;

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          fontSize: 26 + lerpWidth * 6,
          lineHeight: 30 + lerpWidth * 4,
          fontWeight: '700',
          marginBottom: 3 + lerpWidth * 2,
          color: textColor,
        }}
      >
        {String(number).padStart(2, '0')}
      </Text>
      <Text
        style={{
          fontSize: 11 + lerpWidth * 1,
          color: textColor,
        }}
      >
        {title}
      </Text>
    </View>
  );
}

function BackgroundImage({
  maxColumnWidth = MAX_HEADER_WIDTH,
  width = 900,
  height = 498,
  centerOffset = BACKGROUND_CENTER_OFFSET,
  top = -65,
}: {
  maxColumnWidth?: number;
  width?: number;
  height?: number;
  centerOffset?: number;
  top?: number;
}) {
  const screenWidth = useWindowDimensions().width;
  const offset = Math.min(maxColumnWidth / 4, screenWidth / 2 - centerOffset);
  const left = (screenWidth - width) / 2 + offset;

  return (
    <Image
      resizeMode="contain"
      style={{
        width,
        height,
        overflow: 'hidden',
        position: 'absolute',
        left,
        top,
      }}
      source={require('../../assets/gpvnl/gpvnl-background-full-gradient.jpg')}
    />
  );
}

function LogoImage({
  alt,
  maxColumnWidth = MAX_HEADER_WIDTH,
  width = 66,
  height = 63,
  centerOffset = BACKGROUND_CENTER_OFFSET,
  top = 10,
}: {
  alt: string;
  maxColumnWidth?: number;
  width?: number;
  height?: number;
  centerOffset?: number;
  top?: number;
}) {
  const screenWidth = useWindowDimensions().width;
  const offset = Math.min(maxColumnWidth / 4, screenWidth / 2 - centerOffset);
  const left = (screenWidth - width) / 2 + offset;

  return (
    <Image
      accessibilityLabel={alt}
      resizeMode="contain"
      style={{
        width,
        height,
        position: 'absolute',
        top,
        left,
      }}
      source={require('../../assets/gpvnl/gpvnl-logo-white.png')}
    />
  );
}

const getActivePhase = (timeline: Timeline, timestamp: number) =>
  timeline.phases.find((phase) => new Date(phase.endAt).getTime() > timestamp);

const ButtonColor: Record<string, { buttonColor: string; textColor: string }> =
  {
    yellow: { buttonColor: YELLOW, textColor: BLACK },
    white: { buttonColor: WHITE, textColor: BLACK },
    black: { buttonColor: BLACK, textColor: WHITE },
  };

const Icons: Record<
  'black' | 'white',
  Record<string, { uri: string; width: number; height: number }>
> = {
  white: {
    tickets: require('../../assets/icons/white/icon-tickets.png'),
    ranking: require('../../assets/icons/white/icon-ranking.png'),
    location: require('../../assets/icons/white/icon-location.png'),
    link: require('../../assets/icons/white/icon-link.png'),
    info: require('../../assets/icons/white/icon-info.png'),
    arrowRight: require('../../assets/icons/white/icon-arrow-right.png'),
    activity: require('../../assets/icons/white/icon-timeline.png'),
  },
  black: {
    tickets: require('../../assets/icons/black/icon-tickets.png'),
    ranking: require('../../assets/icons/black/icon-ranking.png'),
    location: require('../../assets/icons/black/icon-location.png'),
    link: require('../../assets/icons/black/icon-link.png'),
    info: require('../../assets/icons/black/icon-info.png'),
    arrowRight: require('../../assets/icons/black/icon-arrow-right.png'),
    activity: require('../../assets/icons/black/icon-timeline.png'),
  },
};
