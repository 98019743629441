import Color from 'color';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import {
  Button,
  ButtonProps,
  Dialog,
  Portal,
  Text,
  useTheme,
} from 'react-native-paper';
import { useMatchMedia } from '../hooks/useMatchMedia';
import { useWindowDimensions } from '../utils/useLerpWidth';

interface GlobalDialog {
  active: boolean;
  activeId: string | null;
  title: string;
  content: string;
  positive: {
    icon?: ButtonProps['icon'];
    label: string;
    onPress(): void;
  } | null;
  negative: {
    icon?: ButtonProps['icon'];
    label: string;
    onPress?(): void;
  } | null;
  show: (
    id: string,
    dialog: Pick<GlobalDialog, 'positive' | 'negative' | 'title' | 'content'>
  ) => void;
  hide: () => void;
}

const DEFAULT: Pick<
  GlobalDialog,
  'positive' | 'negative' | 'active' | 'title' | 'content' | 'activeId'
> = {
  active: false,
  title: '',
  content: '',
  positive: null,
  negative: null,
  activeId: null,
};
const DialogContext = createContext<GlobalDialog>({
  ...DEFAULT,
  show: () => {},
  hide: () => {},
});

export function useDialog() {
  const { show, hide } = useContext(DialogContext);
  return useMemo(() => ({ show, hide }), [show, hide]);
}

function useProvideDialog(): GlobalDialog {
  const [dialog, setDialog] = useState(DEFAULT);

  const hide = useCallback(
    () => setDialog((prev) => ({ ...prev, activeId: null, active: false })),
    []
  );

  const show = useCallback(
    (
      id: string,
      dialog: Pick<GlobalDialog, 'positive' | 'negative' | 'title' | 'content'>
    ) => {
      setDialog({
        activeId: id,
        positive: dialog.positive ?? null,
        negative: dialog.negative,
        title: dialog.title,
        content: dialog.content,
        active: true,
      });
    },
    [hide]
  );

  return { ...dialog, show, hide };
}

export function DialogProvider({ children }: { children: React.ReactNode }) {
  const value = useProvideDialog();

  const theme = useTheme();
  const {
    dark,
    colors: { primary, secondary },
  } = theme;
  const accentHasContrast = new Color(secondary).isDark() !== dark;
  const primaryHasContrast = new Color(primary).isDark() !== dark;

  const actionColor = [
    accentHasContrast && secondary,
    primaryHasContrast && primary,
    dark && '#121212',
    '#fff',
  ].find(Boolean) as string;

  const contrastOnAction = new Color(actionColor).isDark() ? '#FFF' : '#000';

  const isSmall = useMatchMedia('(max-width: 560px)');
  const { width } = useWindowDimensions();

  const onPositive = () => {
    value.positive?.onPress();

    value.hide();
  };

  const onNegative = () => {
    if (value.negative?.onPress) {
      value.negative.onPress();
    }

    value.hide();
  };

  return (
    <DialogContext.Provider value={value}>
      {children}

      <Portal>
        <Dialog
          visible={value.active}
          onDismiss={value.hide}
          style={{
            width: '100%',
            maxWidth: isSmall ? width : 560,
            alignSelf: 'center',
            borderRadius: isSmall ? 0 : 10,
            maxHeight: 560,
            height: '100%',
          }}
          theme={{
            ...theme,
            colors: { ...theme.colors, secondary: actionColor },
          }}
        >
          <Dialog.Title>{value.title}</Dialog.Title>
          <Dialog.ScrollArea
            style={{
              paddingHorizontal: 26,
              paddingVertical: 26,
              maxHeight: isSmall ? undefined : 440,
              flex: 1,
              marginBottom: 0,
            }}
          >
            <View>
              <Text variant="bodyMedium">{value.content}</Text>
            </View>
          </Dialog.ScrollArea>
          <Dialog.Actions
            style={{
              paddingHorizontal: 16,
              paddingBottom: 12,
              paddingTop: 12,
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 44 + 12 + 12 + 16,
              flexDirection: 'row',
              width: '100%',
            }}
          >
            {value.negative ? (
              <Button
                icon={value.negative.icon}
                onPress={onNegative}
                textColor={actionColor}
                mode="text"
                labelStyle={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                  paddingHorizontal: 6,
                  minWidth: 80,
                }}
                style={{ flex: 1 }}
              >
                {value.negative.label}
              </Button>
            ) : null}
            {value.positive ? (
              <Button
                icon={value.positive.icon}
                onPress={onPositive}
                buttonColor={actionColor}
                textColor={contrastOnAction}
                mode="contained"
                labelStyle={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                  paddingHorizontal: 6,
                  minWidth: 80,
                }}
                style={{ flex: 1 }}
              >
                {value.positive.label}
              </Button>
            ) : null}
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </DialogContext.Provider>
  );
}
