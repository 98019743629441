import {
  useFocusEffect,
  useIsFocused,
  useLinkTo,
} from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  PixelRatio,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import {
  Button,
  Card,
  Divider,
  Menu,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';
import { Easing } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { Path } from 'react-native-svg';
import { AnimatedIcon } from '../components/AnimatedIcon';
import { AnimatedImage } from '../components/AnimatedImage';
import { NetworkError } from '../components/NetworkError';
import { Header } from '../discover/DiscoveryScreen';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { MediaPlayer } from '../player/MediaPlayer';
import { PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { useArtist } from '../tracks/useArtist';
import {
  ApiVotedTrack,
  ApiVotedTracks,
  useVotedTracks,
} from '../tracks/useVotedTracks';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';
import { OnScreenPlayer } from './OnScreenPlayer';

const SKELETON_TRACK = Object.freeze({ _skeleton: true } as const);
type SkeletonTrackData = typeof SKELETON_TRACK;

const SKELETON_DATA: readonly SkeletonTrackData[] = [
  SKELETON_TRACK,
  SKELETON_TRACK,
  SKELETON_TRACK,
];

export function FavoritesScreen() {
  const { top, left, right } = useSafeAreaInsets();
  return (
    <View
      style={{
        height: '100%',
        flex: 1,
        paddingTop: top,
        paddingLeft: left,
        paddingRight: right,
        position: 'relative',
      }}
    >
      <Tracks />
      <LinearGradient
        colors={[`${PRIMARY_DARK}00`, `${PRIMARY_DARK}1A`]}
        style={{ width: '100%', height: 72, position: 'absolute', bottom: 0 }}
        pointerEvents="none"
      />
      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'center',
        }}
        pointerEvents="box-none"
      >
        <Header />
      </View>
    </View>
  );
}

function Tracks() {
  const mediaPlayerRef = useRef<MediaPlayer>(null);
  const focused = useIsFocused();
  const [playingTrack, setPlayingTrack] = useState<ApiVotedTrack | null>(null);

  const {
    isLoading: isLoadingConfiguration,
    error: configurationError,
    refetch: refetchConfiguration,
  } = usePrivateConfiguration({ enabled: focused });

  const {
    data: tracksData,
    error: tracksError,
    isLoading: isLoadingTracks,
    isRefetching,
    refetch: refetchTracks,
  } = useVotedTracks();

  const refetchAll = useCallback(() => {
    return refetchConfiguration().then(() => refetchTracks());
  }, [refetchTracks, refetchConfiguration]);

  const isLoading = isLoadingConfiguration || isLoadingTracks;
  const error = configurationError || tracksError;

  const playTrack = useCallback(
    (track: ApiVotedTrack) => {
      if (!track.track._links.wave?.href) {
        return;
      }

      setPlayingTrack(track);

      const uri = track.track._links.wave.href.replace(
        /(%7[Bb]|{)variant(%7[Dd]|})/,
        `160b`
      );

      mediaPlayerRef.current!.play(uri, track.track.name, 'audio');
    },
    [mediaPlayerRef, setPlayingTrack]
  );

  const gotoPath = useLinkTo();
  const gotoVideo = useCallback(
    (track: ApiVotedTrack) => {
      if (!track.track._links.stream?.href) {
        return;
      }

      gotoPath(`/player/${prepareUrl(track.track._links.self.href)}`);
    },
    [gotoPath]
  );

  // On blur, remove playing track
  useFocusEffect(
    useCallback(() => {
      return () => {
        mediaPlayerRef.current?.stop();
        setPlayingTrack(null);
      };
    }, [setPlayingTrack])
  );

  const [filtered, setFiltered] = useState<ApiVotedTrack[]>([]);

  const ordered = useMemo(
    () =>
      filtered
        ? filtered.sort(({ track: a }, { track: b }) => b.vote - a.vote)
        : [],
    [filtered]
  );

  const renderItem = useMemo(
    () => makeRenderItem(playTrack, gotoVideo, isLoading, refetchAll),
    [playTrack, gotoVideo, isLoading, refetchAll]
  );

  const empty = !tracksData || tracksData.tracks._embedded.length === 0;
  const showSkeleton = isLoading || (isRefetching && empty);

  const data: readonly (SkeletonTrackData | ApiVotedTrack | Error)[] =
    showSkeleton ? SKELETON_DATA : empty ? [error!].filter(Boolean) : ordered;

  return (
    <MediaPlayer ref={mediaPlayerRef}>
      <React.Fragment>
        <Filters source={tracksData} setFiltered={setFiltered} />
        <Divider style={{ height: StyleSheet.hairlineWidth }} />
      </React.Fragment>

      <FlatList
        nativeID="scroller"
        data={data}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        style={{ flex: 1 }}
        contentContainerStyle={{
          maxWidth: 800,
          alignSelf: 'center',
          width: '100%',
          paddingBottom: 124,
        }}
      />

      <OnScreenPlayer
        playerRef={mediaPlayerRef}
        visible={playingTrack !== null}
        track={playingTrack}
      />
    </MediaPlayer>
  );
}

function Filters({
  source,
  setFiltered,
}: {
  source?: ApiVotedTracks;
  setFiltered: (next: ApiVotedTrack[]) => void;
}) {
  const [minVoteActive, setMinVoteActive] = useState(false);
  const [minVote, setMinVote] = useState(4);
  const changeMinVote = useCallback(
    (next: number) => {
      setMinVote(next);
      setMinVoteActive(false);
    },
    [setMinVote, setMinVoteActive]
  );

  useEffect(() => {
    if (!source) {
      return;
    }

    const { tracks } = source;

    setFiltered(
      tracks._embedded.slice().filter(({ track }) => track.vote >= minVote)
    );
  }, [source, minVote, setFiltered]);

  return (
    <View style={{ height: 80 + 64, paddingTop: 64 }}>
      <View
        style={{
          maxWidth: 800,
          width: '100%',
          alignSelf: 'center',
          marginHorizontal: 'auto',
          position: 'relative',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          paddingHorizontal: 36,
          paddingVertical: 16,
          height: '100%',
        }}
      >
        <Menu
          onDismiss={() => setMinVoteActive(false)}
          visible={minVoteActive}
          anchor={
            <Button
              style={{
                position: 'relative',
              }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              uppercase={false}
              mode="contained-tonal"
              onPress={() => setMinVoteActive(true)}
              icon="chevron-down"
            >
              {i18n.translate(`app.swipe.labels.${minVote}`)}
            </Button>
          }
        >
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.5`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(5);
            }}
          />
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.4`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(4);
            }}
          />
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.3`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(3);
            }}
          />
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.2`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(2);
            }}
          />
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.1`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(1);
            }}
          />
          <Menu.Item
            title={i18n.translate(`app.swipe.labels.0`)}
            titleStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              changeMinVote(0);
            }}
          />
        </Menu>
      </View>
    </View>
  );
}

function isSkeletonTrack(
  item: ApiVotedTrack | SkeletonTrackData | Error
): item is SkeletonTrackData {
  return item === SKELETON_TRACK;
}

function keyExtractor(
  item: ApiVotedTrack | SkeletonTrackData | Error,
  index: number
) {
  if (isSkeletonTrack(item)) {
    return `skeleton-${index}`;
  }

  if (item instanceof Error) {
    return 'error';
  }

  return item.track._links.self.href;
}

function makeRenderItem(
  playTrack: (track: ApiVotedTrack) => void,
  gotoVideo: (track: ApiVotedTrack) => void,
  loading: boolean,
  reload: () => void
) {
  return function renderItem(
    item: ListRenderItemInfo<ApiVotedTrack | SkeletonTrackData | Error>
  ) {
    if (isSkeletonTrack(item.item)) {
      return <SkeletonTrack index={item.index} />;
    }

    if (item.item instanceof Error) {
      return (
        <View style={{ width: '100%', marginTop: 12 }}>
          <NetworkError error={item.item} loading={loading} retry={reload} />
        </View>
      );
    }

    return (
      <VotedTrack
        track={item.item}
        play={playTrack}
        view={gotoVideo}
        index={item.index}
      />
    );
  };
}

function SkeletonTrack({ index }: { index: number }) {
  const { dark } = useTheme();

  return (
    <Fragment>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 36,
          paddingVertical: 16,
          height: PixelRatio.roundToNearestPixel(84),
          opacity: 0.8 / (index + 1),
        }}
      >
        <Card
          style={{
            width: 52,
            height: 52,
            borderRadius: 5,
            backgroundColor: dark ? PRIMARY_LIGHT : PRIMARY_DARK,
          }}
        >
          <Fragment />
        </Card>
        <View style={{ marginLeft: 16, justifyContent: 'center', flex: 1 }}>
          <Text
            variant="titleSmall"
            style={{
              fontWeight: '600',
              color: dark ? '#FEFEFE' : PRIMARY_DARK,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {' '}
          </Text>
          <Text
            variant="labelMedium"
            style={{
              fontWeight: 'normal',
              marginTop: 2,
              color: dark ? '#FEFEFEAA' : PRIMARY_DARK,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {' '}
          </Text>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              marginLeft: 16,
              alignItems: 'center',
              minHeight: 18,
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: dark ? `#FFFFFF1A` : `${PRIMARY_DARK}1A`,
              borderRadius: 40,
              paddingHorizontal: 4,
              width: 36,
            }}
          >
            <Svg
              viewBox="0 0 11 10"
              style={{ width: 11, height: 10, marginRight: 4 }}
            >
              <Path
                fill="#ffbe16"
                d="M5.5,0,7.15,3.357,11,3.82,8.17,6.357,8.9,10,5.5,8.211,2.1,10,2.83,6.357,0,3.82l3.85-.463Z"
              />
            </Svg>

            <Text
              style={{
                fontSize: 12,
                color: dark ? 'white' : PRIMARY_DARK,
                display: 'flex',
              }}
            >
              {' '}
            </Text>
          </View>
        </View>
      </View>
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />
    </Fragment>
  );
}

function VotedTrack({
  track,
  play,
  view,
  index,
}: {
  track: ApiVotedTrack;
  play: (track: ApiVotedTrack) => void;
  view: (track: ApiVotedTrack) => void;
  index?: number;
}) {
  const { track: votedTrack } = track;

  const url = votedTrack._links.artist?.href;
  const { data } = useArtist(url);
  const { artist } = data || { artist: null };

  const src = votedTrack._links.cover_image?.href;
  const playable = Boolean(votedTrack._links.wave?.href);
  const viewable = Boolean(votedTrack._links.stream?.href);

  const size = IMAGE_PIXEL_RATIO * 52;
  const uri = variantImageUrl(src);

  const { dark } = useTheme();

  return (
    <Pressable onPress={viewable ? () => view(track) : () => play(track)}>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 36,
          paddingVertical: 16,
          height: PixelRatio.roundToNearestPixel(84),
        }}
      >
        {playable || viewable ? (
          <Surface
            style={{
              width: 52,
              height: 52,
              borderRadius: 5,
              backgroundColor: dark ? PRIMARY_LIGHT : PRIMARY_DARK,
            }}
            elevation={1}
            accessibilityLabel={`Play ${votedTrack.name} by ${artist?.name}"`}
          >
            {uri ? <CoverImage size={size} uri={uri} /> : null}
            <AnimatedIcon
              animateScale={0.2}
              animateOpacity
              delay={25 * (index ?? 0)}
              easing={Easing.in(Easing.cubic)}
              overshootClamping
              style={{
                position: 'absolute',
                top: (52 - 28) / 2,
                left: (52 - 28) / 2,
                width: 28,
                height: 28,
                borderRadius: 14,
                backgroundColor: 'white',
              }}
            >
              <Svg
                width={11}
                height={10}
                viewBox="0 0 20 23"
                style={{
                  position: 'absolute',
                  top: 9,
                  left: 9,
                }}
              >
                <Path
                  d="M9.766,3.015a2,2,0,0,1,3.468,0L21.277,17a2,2,0,0,1-1.734,3H3.457a2,2,0,0,1-1.734-3Z"
                  transform="translate(20) rotate(90)"
                  fill={PRIMARY_DARK}
                />
              </Svg>
            </AnimatedIcon>
          </Surface>
        ) : (
          <Surface
            style={{
              width: 52,
              height: 52,
              borderRadius: 5,
              backgroundColor: dark ? PRIMARY_LIGHT : PRIMARY_DARK,
            }}
            elevation={1}
          >
            {uri ? <CoverImage size={size} uri={uri} /> : null}
          </Surface>
        )}
        <View style={{ marginLeft: 16, justifyContent: 'center', flex: 1 }}>
          <Text
            variant="titleSmall"
            style={{
              fontWeight: '600',
              color: dark ? '#FEFEFE' : PRIMARY_DARK,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {votedTrack.name}
          </Text>
          <Text
            variant="labelMedium"
            style={{
              fontWeight: 'normal',
              marginTop: 2,
              color: dark ? '#FEFEFEAA' : PRIMARY_DARK,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {artist?.name || '-'}
          </Text>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              marginLeft: 16,
              alignItems: 'center',
              minHeight: 18,
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: dark ? `#FFFFFF1A` : `${PRIMARY_DARK}1A`,
              borderRadius: 40,
              paddingHorizontal: 4,
              width: 36,
            }}
          >
            <Svg
              viewBox="0 0 11 10"
              style={{ width: 11, height: 10, marginRight: 4 }}
              accessibilityLabel={`You have rated this track a ${votedTrack.vote}`}
            >
              <Path
                fill="#ffbe16"
                d="M5.5,0,7.15,3.357,11,3.82,8.17,6.357,8.9,10,5.5,8.211,2.1,10,2.83,6.357,0,3.82l3.85-.463Z"
              />
            </Svg>

            <Text
              style={{
                fontSize: 12,
                color: dark ? 'white' : PRIMARY_DARK,
                display: 'flex',
              }}
            >
              {votedTrack.vote}
            </Text>
          </View>
        </View>
      </View>
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />
    </Pressable>
  );
}

function CoverImage({ size, uri }: { size: number; uri: string }) {
  const { dark } = useTheme();

  return (
    <AnimatedImage
      animateScale={0.8}
      transition={{
        overshootClamping: true,
        easing: Easing.in(Easing.cubic),
        delay: 120,
      }}
      source={{ uri, width: size, height: size }}
      style={{
        width: 52,
        height: 52,
        borderRadius: 5,
        backgroundColor: dark ? PRIMARY_LIGHT : PRIMARY_DARK,
        position: 'absolute',
        left: 0,
        top: 0,
      }}
    />
  );
}
