import { fetchMedia, FetchMediaError, fetchMediaWrapped } from 'fetch-media';
import cloneDeep from 'lodash.clonedeep';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import {
  QueryClient,
  QueryKey,
  UseMutateAsyncFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useLocale } from '../hooks/useLocale';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { useToken } from '../hooks/useToken';
import { i18n } from '../locale';
import { authorization } from '../utils/authorization';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';

export type OnboardedFeature =
  | 'preferences.2020-07-20'
  | 'how-to-swipe.2020-07-20';

export type Consent =
  | 'new-track.push.2020-08-15'
  | 'new-event.push.2020-08-15'
  | 'shared-track-rating.push.2020-08-15'
  | 'new-track.email.2020-08-15'
  | 'new-event.email.2020-08-15'
  | 'shared-track-rating.email.2020-08-15'
  | 'gpvnl.participation-price.2023-03-12';

export type AuthProvider = 'facebook' | 'spotify' | 'apple' | 'google';

export type RemoteDiscoveryVoteProfile = {
  danceable: number;
  faithful: number;
  intense: number;
  multiple: number;
  relaxed: number;
  traditional: number;
};

export type RemoteAccountProfile = {
  _links: {
    self: { href: string };
    artist_profile?: { href: string };
    profile_image: { href: string; templated: true; exists: boolean };
    profile_tags: { href: string };
    rated_tracks: { href: string; count: number };
    country?: { href: string; code2: string };
  };

  name: string | null;
  email: string;
  hit_prediction: null;
  discovery_vote_profile?: null | RemoteDiscoveryVoteProfile;
  auth_providers: AuthProvider[];
  born_at: string | null;
  gender: string | null;

  onboarded_features: OnboardedFeature[];
  consent: Consent[];
};

type PatchAccountProfile = {
  _links: {
    self: { href: string };
  };

  country_code?: string | null;
  profile_tags?: string[] | undefined;
} & Partial<
  Pick<
    RemoteAccountProfile,
    'name' | 'born_at' | 'gender' | 'onboarded_features' | 'consent'
  >
>;

const PROFILE_IMAGE_STATE = { current: '' };

export function useThumbnail() {
  const { data: profile } = useProfile();
  const ratio = IMAGE_PIXEL_RATIO;
  const size = ratio * 52;
  const imageBaseUri = profile?._links.profile_image.href;
  const variantUri = variantImageUrl(imageBaseUri, 'thumbnail');

  const uri =
    variantUri && profile?._links.profile_image.exists
      ? [variantUri, PROFILE_IMAGE_STATE.current].join('#')
      : undefined;

  return { uri, size };
}

export function useProfileImage() {
  const { data: profile } = useProfile();
  const ratio = IMAGE_PIXEL_RATIO;
  const size = ratio * 150;
  const imageBaseUri = profile?._links.profile_image.href;
  const variantUri = variantImageUrl(imageBaseUri, 'size');

  const uri = variantUri;

  return { uri, size };
}

export function useProfile({
  enabled = true,
  ...options
}: UseQueryOptions<RemoteAccountProfile, FetchMediaError | Error> = {}) {
  const { ref, token } = useToken();
  const { data } = usePrivateConfiguration();
  const url = data?.configuration._links.my_profile.href;
  const locale = useLocale();

  return useQuery(
    [locale, url, 'profile'] as QueryKey,
    async ({ signal }) => {
      const response = await fetchMedia(url!, {
        headers: {
          authorization: authorization(ref.current)!,
          accept: 'application/vnd.soundersmusic.profile.v1.private+json',
          acceptLanguage: locale,
        },
        method: 'GET',
        signal,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (!response || typeof response !== 'object') {
        throw new Error(
          `Expected object with profile, actual: ${typeof response}`
        );
      }

      if (!Object.prototype.hasOwnProperty.call(response, 'profile')) {
        throw new Error(
          `Expected object with profile, actual: ${Object.keys(response).join(
            ', '
          )}`
        );
      }

      return (response as { profile: RemoteAccountProfile }).profile;
    },
    { enabled: Boolean(url && enabled && token), ...options }
  );
}

type UpdateProfileParams = {
  patch: PatchAccountProfile;
};

type UpdateProfileContext = {
  previous: RemoteAccountProfile | undefined;
  previousUrl: string | undefined;
};

export type UpdateFn = UseMutateAsyncFunction<
  RemoteAccountProfile,
  FetchMediaError | Error,
  UpdateProfileParams,
  UpdateProfileContext
>;

export function useUpdateProfile(
  options: Omit<
    UseMutationOptions<
      RemoteAccountProfile,
      FetchMediaError | Error,
      UpdateProfileParams,
      UpdateProfileContext
    >,
    'onMutate' | 'onError' | 'onSettled'
  > = {}
) {
  const queryClient = useQueryClient();

  const { ref } = useToken();
  const { data } = usePrivateConfiguration();
  const { data: profile } = useProfile();
  const [version, incrementVersion] = useReducer(
    (state: number) => state + 1,
    0
  );

  const url = data?.configuration._links.my_profile.href;

  // This holds the reference to the "latest" data
  const profileRef = useRef<RemoteAccountProfile | null>(profile || null);

  // Update the references as soon as the self URL changes (for example when)
  // the authorization changes but not through logout/login cycle.
  useEffect(() => {
    if (!profile) {
      return;
    }

    profileRef.current = cloneDeep(profile);
    incrementVersion();
  }, [profile?._links.self.href, incrementVersion]);

  const prepare = useCallback(
    ({
      next,
      nextCountryCode,
      nextProfileTags,
    }: {
      next?: RemoteAccountProfile;
      nextCountryCode?: string;
      nextProfileTags?: string[];
    }) => {
      if (!url) {
        return;
      }

      const nextData = next ?? profileRef.current;
      if (!nextData) {
        return;
      }

      return preparePatch({
        queryClient,
        url,
        next: nextData,
        nextCountryCode,
        nextProfileTags,
      });
    },
    [queryClient, url]
  );

  const mutation = useMutation<
    RemoteAccountProfile,
    FetchMediaError | Error,
    UpdateProfileParams,
    UpdateProfileContext
  >(
    async ({ patch }) => {
      if (!patch) {
        throw new Error('Requires new profile data');
      }

      const nextUrl = patch._links.self.href;
      if (!nextUrl || !url) {
        throw new Error('Requires profile url to update profile');
      }

      return updateProfile({
        url: nextUrl,
        authorization: authorization(ref.current)!,
        patch,
      });
    },
    {
      onMutate: async ({ patch }) => {
        if (!url) {
          throw new Error('Expected url, actual: none');
        }

        if (!ref.current) {
          throw new Error('Expected authorization, actual: none');
        }

        if (!patch) {
          throw new Error('Requires new profile data');
        }

        const nextUrl = patch._links.self.href;

        // Cancel queries based on previous (configuration) URL
        await queryClient.cancelQueries(url);
        await queryClient.cancelQueries([i18n.locale, url]);

        // Cancel queries based on the data URL
        await queryClient.cancelQueries(nextUrl);
        await queryClient.cancelQueries([i18n.locale, nextUrl]);

        // Get the current value based on the configuration URL
        const previous: RemoteAccountProfile | undefined =
          queryClient.getQueryData([url, 'profile']);

        if (!previous) {
          return { previous: undefined, previousUrl: undefined };
        }

        // Store the new data (optimistically)
        profileRef.current = cloneDeep(patchProfile(previous, patch)) || null;

        // Store the new data in the query cache (optimistically)
        queryClient.setQueryData(
          [nextUrl, 'profile'],
          cloneDeep(profileRef.current)
        );

        // Return the PREVIOUS data in order to rollback
        return { previous, previousUrl: url };
      },

      onError: (error, variables, context) => {
        console.error('[profile] update failed', error);

        profileRef.current = context?.previous
          ? cloneDeep(context.previous)
          : null;

        if (!context?.previousUrl) {
          return;
        }

        queryClient.setQueryData(
          [context.previousUrl, 'profile'],
          profileRef.current
        );
      },

      onSettled: (_data, _error, _variables, _contxt) => {
        incrementVersion();
        return Promise.all([
          queryClient.invalidateQueries(url),
          queryClient.invalidateQueries([i18n.locale, url]),
        ]);
      },

      ...options,
    }
  );

  return { mutation, profileRef, prepare, version };
}

function preparePatch({
  queryClient,
  url,
  next,
  nextCountryCode,
  nextProfileTags,
}: {
  queryClient: QueryClient;
  url: string;
  next: RemoteAccountProfile;
  nextCountryCode?: string;
  nextProfileTags?: string[];
}) {
  const previous: RemoteAccountProfile | undefined = queryClient.getQueryData([
    url,
    'profile',
  ]);

  const patch: PatchAccountProfile = {
    _links: {
      self: { href: next._links.self.href },
    },
  };

  // Patch the country
  if (
    nextCountryCode !== undefined &&
    next._links.country?.code2 !== nextCountryCode
  ) {
    patch.country_code = nextCountryCode;
  }

  // Patch the profile tags
  if (nextProfileTags !== undefined) {
    patch.profile_tags = nextProfileTags;
  }

  // Patch all the other fields
  if (!previous || next.name !== previous.name) {
    patch.name = next.name;
  }

  if (
    !previous ||
    next.born_at?.slice(0, '2000-01-01'.length) !==
      previous.born_at?.slice(0, '2000-01-01'.length)
  ) {
    patch.born_at = next.born_at;
  }

  if (!previous || next.gender !== previous.gender) {
    patch.gender = next.gender;
  }

  if (
    !previous ||
    JSON.stringify(next.onboarded_features.slice().sort()) !==
      JSON.stringify(previous.onboarded_features.slice().sort())
  ) {
    patch.onboarded_features = next.onboarded_features.slice().sort();
  }

  if (
    !previous ||
    JSON.stringify(next.consent.slice().sort()) !==
      JSON.stringify(previous.consent.slice().sort())
  ) {
    patch.consent = next.consent.slice().sort();
  }

  return patch;
}

export function useUpdateProfileImage() {
  const queryClient = useQueryClient();

  const { data: profile, refetch } = useProfile();
  const { token, ref } = useToken();
  const url = profile?._links.self.href;
  const imageUrl = profile?._links.profile_image.href;

  return useMutation(
    async (next: FormData) => {
      if (!imageUrl) {
        throw new Error('Requires profile image url to update profile');
      }

      const auth = authorization(ref.current);
      if (!auth) {
        throw new Error(
          'Requires authorization (logged-in session) to update profile'
        );
      }

      updateProfileImage({
        url: imageUrl,
        authorization: auth,
        formData: next,
      });
    },
    {
      onMutate: () => {
        return Promise.all([
          queryClient.cancelQueries(url),
          queryClient.cancelQueries([i18n.locale, url]),
        ]);
      },
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(url),
          queryClient.invalidateQueries([i18n.locale, url]),
        ]);
      },
    }
  );
}

async function updateProfile({
  url,
  authorization,
  patch: { _links, ...patch },
}: {
  url: string;
  authorization: string;
  patch: PatchAccountProfile;
}) {
  const result = await fetchMedia(url, {
    headers: {
      accept: 'application/vnd.soundersmusic.profile.v1.private+json',
      acceptLanguage: i18n.locale,
      authorization,
      contentType: 'application/vnd.soundersmusic.profile.v1.patch+json',
    },
    method: 'PUT',
    body: { patch },
    debug: __DEV__,
    disableFormData: true,
    disableFormUrlEncoded: true,
  });

  if (!result || typeof result !== 'object') {
    throw new Error(`Expected object with profile, actual ${typeof result}`);
  }

  if (!Object.prototype.hasOwnProperty.call(result, 'profile')) {
    throw new Error(
      `Expected object with profile, actual ${Object.keys(result).join(', ')}`
    );
  }

  return (result as { profile: RemoteAccountProfile }).profile;
}

async function updateProfileImage({
  url,
  authorization,
  formData,
}: {
  url: string;
  authorization: string;
  formData: FormData;
}) {
  const response = await fetchMediaWrapped(url, {
    headers: { accept: 'application/vnd.soundersmusic.empty', authorization },
    method: 'PUT',
    body: formData,
  });

  // Only error if 4xx, 5xx
  if (response.response.status >= 400 && response.response.status <= 599) {
    response.unwrap();
  }
}

function patchProfile(
  profile: RemoteAccountProfile | null,
  patch: PatchAccountProfile
): RemoteAccountProfile | null {
  if (!profile) {
    return null;
  }

  const { country_code, profile_tags, _links, ...fields } = patch;
  return {
    ...profile,

    // Patch regular fields
    ...fields,

    _links: {
      ...profile._links,
      ..._links,

      // Patch country
      country:
        country_code === undefined
          ? profile._links.country
          : country_code === null
          ? undefined
          : {
              href: '',
              code2: country_code,
            },
    },
  };
}
