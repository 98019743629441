import Color from 'color';
import { AVPlaybackStatusSuccess } from 'expo-av';
import { View } from 'moti';
import React, {
  RefObject,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {
  ImageRequireSource,
  Image,
  PixelRatio,
  ScrollView,
} from 'react-native';
import { Card, IconButton, Text, useTheme } from 'react-native-paper';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { G, Path } from 'react-native-svg';
import { ProgressControl } from '../favorites/OnScreenPlayer';
import { i18n } from '../locale';
import { MediaPlayer } from '../player/MediaPlayer';
import { PASTEL_PURPLE, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { useForceUpdate } from '../utils/useForceUpdate';
import { useThemedColor } from '../utils/useThemedColor';

export function BottomControls({
  playerRef,
  total,
  completed,
  allowSeek = false,
  fixedTheme = false,
}: {
  playerRef: RefObject<MediaPlayer | null>;
  total?: number | undefined;
  completed?: number | undefined;
  allowSeek?: boolean;
  fixedTheme?: boolean;
}) {
  const {
    colors: { primary, surface, onSurface: text },
  } = useTheme();
  const { bottom } = useSafeAreaInsets();

  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: 32,
        width: '100%',
        paddingBottom: bottom,
      }}
    >
      <ProgressControl
        playerRef={playerRef}
        allowSeek={allowSeek}
        showProgress
        padding={21 * 2}
        height={30 + 21}
        seekTopOffset={8}
        barColor={fixedTheme ? PASTEL_PURPLE : primary}
        barBackgroundColor={fixedTheme ? `${PASTEL_PURPLE}40` : undefined}
        backgroundColor={useMemo(
          () =>
            fixedTheme
              ? 'rgba(29, 15, 52, 0.1)'
              : new Color(surface).darken(0.1).desaturate(0.3).toString(),
          [surface, fixedTheme]
        )}
      />
      {total !== undefined && completed !== undefined ? (
        <ProgressLabel total={total} completed={completed} color={text} />
      ) : null}
    </Animated.View>
  );
}

function ProgressLabel({
  completed,
  total,
  color,
}: {
  completed: number;
  total: number;
  color?: string;
}) {
  const actualColor = useThemedColor(
    color ?? PRIMARY_DARK,
    color ?? PRIMARY_LIGHT
  );

  return (
    <Text
      variant="labelSmall"
      style={{
        marginHorizontal: 23,
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 16,
        fontSize: 12,
        color: actualColor,
        display: 'flex',
      }}
    >
      Track{' '}
      <Text
        variant="labelSmall"
        style={[
          {
            color: actualColor,
            fontWeight: 'bold',
            fontSize: 12,
            marginRight: 4,
          },
        ]}
      >
        {completed + 1}
      </Text>{' '}
      {i18n.translate('app.event.progress_binder')}{' '}
      <Text
        variant="labelSmall"
        style={[{ color: actualColor, fontWeight: 'bold', fontSize: 12 }]}
      >
        {total}
      </Text>
    </Text>
  );
}

export function VideoBottomControls({
  playerRef,
  brandingSrc,
  allowSeek = false,
  fixedTheme = false,
  coverSrc,
  title,
  description,
  bottom: overrideBottom,
  lyrics,
  actualHeight = 300 + 230,
}: {
  playerRef: RefObject<MediaPlayer | null>;
  brandingSrc?: null | ImageRequireSource;
  allowSeek?: boolean;
  fixedTheme?: boolean;
  coverSrc?: string;
  title: string;
  description: string;
  bottom?: number;
  lyrics: string;
  actualHeight?: number;
}) {
  const {
    colors: { primary, surface, secondary: accent, onSurface: text },
  } = useTheme();
  const { bottom } = useSafeAreaInsets();
  const force = useForceUpdate();
  const statusRef = useRef<AVPlaybackStatusSuccess | null>(null);

  const ratio = Math.min(1, Math.min(3, Math.round(PixelRatio.get()))) + 1;
  const uri = coverSrc
    ? coverSrc.replace(/(%7[Bb]|{)variant(%7[Dd]|})/, `size_${ratio}x`)
    : undefined;

  useEffect(() => {
    const remove = playerRef.current?.addStatusListener((status) => {
      if (statusRef.current?.isPlaying !== status.isPlaying) {
        statusRef.current = status;
        force();
      } else {
        statusRef.current = status;
      }
    });

    return () => {
      remove && remove();
    };
  }, [playerRef]);

  const togglePlay = playerRef.current?.togglePlay;
  const [showCaptions, toggleCaptions] = useReducer((prev) => !prev, false);

  return (
    <Animated.View
      style={{
        width: '100%',
        paddingBottom: overrideBottom ?? bottom,
        borderRadius: 16,
        maxWidth: 542 + 37 * 2 + 32 - 64,
      }}
    >
      {showCaptions && lyrics ? (
        <ScrollView
          style={{
            backgroundColor: '#000000CC',
            borderTopRightRadius: 16,
            borderTopLeftRadius: 16,
            width: '100%',
            maxHeight: Math.min(360, Math.max(100, actualHeight - 230)),
            paddingHorizontal: 24,
            paddingTop: 16,
            paddingBottom: 24,
            marginBottom: -16,
          }}
          contentContainerStyle={{
            paddingTop: 16,
            paddingBottom: 32,
          }}
        >
          <View>
            <Text
              variant="bodyLarge"
              style={{
                color: '#fff',
                marginBottom: 8,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Lyrics
            </Text>
            <Text variant="bodyMedium" style={{ color: '#fff' }}>
              {lyrics}
            </Text>
          </View>
        </ScrollView>
      ) : null}

      <View
        style={{ width: '100%', borderRadius: 16, backgroundColor: surface }}
      >
        <View
          style={{
            marginBottom: 4,
            width: '100%',
            paddingTop: 16,
            paddingHorizontal: 24,
            flexDirection: 'row',
          }}
        >
          <Card
            style={{
              width: 48,
              height: 48,
              borderRadius: 4,
              backgroundColor: primary,
              marginRight: 16,
              flexShrink: 0,
            }}
            onPress={togglePlay}
          >
            {uri ? (
              <Image
                source={{ uri, width: 48, height: 48 }}
                resizeMode="cover"
                style={{
                  borderRadius: 4,
                  width: 48,
                  height: 48,
                  backgroundColor: primary,
                }}
              />
            ) : null}
            <View
              style={{
                position: 'absolute',
                top: (48 - 28) / 2,
                left: (48 - 28) / 2,
                width: 28,
                height: 28,
                borderRadius: 14,
                backgroundColor: 'white',
              }}
            >
              {statusRef.current?.isPlaying ? (
                <Svg
                  viewBox="0 0 9 9"
                  width={10}
                  height={10}
                  style={{
                    position: 'absolute',
                    top: 9,
                    left: 9,
                  }}
                  accessibilityLabel="Pause song"
                >
                  <G
                    fill="none"
                    stroke={PRIMARY_DARK}
                    strokeLinecap="round"
                    strokeWidth={3}
                  >
                    <Path d="M0 0L0 6" transform="translate(1.5 1.5)" />
                    <Path d="M0 0L0 6" transform="translate(7.5 1.5)" />
                  </G>
                </Svg>
              ) : (
                <Svg
                  width={11}
                  height={10}
                  viewBox="0 0 20 23"
                  style={{
                    position: 'absolute',
                    top: 9,
                    left: 9,
                  }}
                  accessibilityLabel="Play song"
                >
                  <Path
                    d="M9.766,3.015a2,2,0,0,1,3.468,0L21.277,17a2,2,0,0,1-1.734,3H3.457a2,2,0,0,1-1.734-3Z"
                    transform="translate(20) rotate(90)"
                    fill={PRIMARY_DARK}
                  />
                </Svg>
              )}
            </View>
          </Card>

          <View style={{ flexDirection: 'column', flex: 1 }}>
            <Text
              variant="labelSmall"
              style={{
                color: text,
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: 24,
                display: 'flex',
              }}
              numberOfLines={1}
            >
              {title}
            </Text>
            <Text
              variant="labelSmall"
              style={{
                color: text,
                fontSize: 16,
                lineHeight: 24,
                display: 'flex',
              }}
              numberOfLines={1}
            >
              {description}
            </Text>
          </View>

          {lyrics ? (
            <IconButton
              icon={showCaptions ? 'closed-caption' : 'closed-caption-outline'}
              style={{ marginRight: -10 }}
              onPress={toggleCaptions}
            />
          ) : null}
        </View>

        <ProgressControl
          playerRef={playerRef}
          allowSeek={allowSeek}
          showProgress
          padding={0}
          height={30 + 21}
          seekTopOffset={8}
          barColor={accent}
          barBackgroundColor={primary}
          backgroundColor={surface}
          paddingHorizontal={24}
        />
      </View>
    </Animated.View>
  );
}
