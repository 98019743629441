import * as Notifications from 'expo-notifications';
import { StatusBar } from 'expo-status-bar';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider as ThemeProvider } from 'react-native-paper';
import useColorScheme from '../hooks/useColorScheme';
import { TokenProvider } from '../hooks/useToken';
import Navigation from '../navigation';
import { NavigationReadyProvider } from '../navigation/NavigationReadyContext';
import { init } from '../navigation/useBranchSubscription';
import { BASE_THEME, DARK_THEME } from '../theming';
import { Connectivity } from './Connectivity';
import { DialogProvider } from './DialogContextProvider';
import { OtaUpdater } from './OtaUpdater';
import { QueryCacheProvider } from './QueryCache';
import { SnackbarProvider } from './SnackbarProvider';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

init();

export function Root() {
  const colorScheme = useColorScheme();

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const root = document.getElementById('root');

    if (root) {
      // This ensures that all native components are in the correct style (when
      // supported by the browser). A better solution may be explored using both
      // values on the root html file, but that's to be explored.
      root.style.colorScheme = colorScheme;
    }
  }, [colorScheme]);

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <QueryCacheProvider>
        <TokenProvider>
          <ThemeProvider
            theme={colorScheme !== 'light' ? DARK_THEME : BASE_THEME}
          >
            <DialogProvider>
              <SnackbarProvider>
                <NavigationReadyProvider>
                  <Navigation />
                  <Connectivity />
                  <StatusBar
                    translucent
                    style={colorScheme === 'dark' ? 'light' : 'dark'}
                    backgroundColor={
                      colorScheme === 'dark' ? '#00000040' : '#FFFFFF40'
                    }
                  />
                  {Platform.OS !== 'web' ? <OtaUpdater /> : null}
                </NavigationReadyProvider>
              </SnackbarProvider>
            </DialogProvider>
          </ThemeProvider>
        </TokenProvider>
      </QueryCacheProvider>
    </GestureHandlerRootView>
  );
}
