import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useKeepAwake } from 'expo-keep-awake';
import { RefObject, useRef } from 'react';
import { Platform, StatusBar, useWindowDimensions, View } from 'react-native';
import { Appbar } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AspectRatio } from '../components/AspectRatio';
import { resetToPath } from '../navigation/utils';
import { MediaPlayer } from '../player/MediaPlayer';
import { VideoBottomControls } from '../swipe/BottomControls';
import { SwipeVideo } from '../swipe/SwipeExperience';
import { useArtist } from '../tracks/useArtist';
import { ApiTrack, useTrack } from '../tracks/useTrack';
import { RootStackParamList } from '../types';

export function StandalonePlayer() {
  const focused = useIsFocused();
  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Player'>>().params ?? {};

  const playerRef = useRef<MediaPlayer>(null);
  const { data } = useTrack(url, { enabled: focused });

  return (
    <MediaPlayer ref={playerRef}>
      <View
        style={{
          paddingTop: 0,
          maxWidth: 800,
          width: '100%',
          marginHorizontal: 'auto',
          alignSelf: 'center',
          position: 'relative',
          zIndex: 0,
        }}
      >
        {data?.track ? (
          <ResponsivePlayer track={data} playerRef={playerRef} />
        ) : (
          <Header />
        )}
      </View>
    </MediaPlayer>
  );
}

function ResponsivePlayer({
  track,
  playerRef,
}: {
  track: ApiTrack;
  playerRef: RefObject<MediaPlayer | null>;
}) {
  const { width, height } = useWindowDimensions();
  const { left, bottom, right } = useSafeAreaInsets();

  const widthToUse = width;
  const heightToUse = height - 0 * 2;

  const limitedWidth = Math.min(widthToUse, 600);
  const limitedHeight = Math.min(1000, heightToUse);

  const limit =
    widthToUse / heightToUse > 375 / 812 ? limitedHeight : limitedWidth;
  const dimRatio = 375 / 812;

  const actualHeight =
    limit === limitedHeight ? limitedHeight : limitedWidth / dimRatio;
  const actualWidth =
    limit === limitedWidth ? limitedWidth : limitedHeight * dimRatio;

  const {
    track: {
      _links: { cover, stream, artist },
      name: title,
      lyrics,
    },
  } = track;

  const { data } = useArtist(artist?.href, { enabled: Boolean(artist?.href) });
  const description = data?.artist?.name || '';

  useKeepAwake(undefined, { suppressDeactivateWarnings: true });

  return (
    <View
      style={{
        width: '100%',
        height: actualHeight,
        position: 'relative',
      }}
    >
      <AspectRatio
        style={{
          aspectRatio: 9 / 16,
          width: Math.min(limitedWidth, Math.floor((actualHeight / 16) * 9)),
          height: actualHeight,
          position: 'absolute',
          zIndex: 0,
        }}
      >
        {stream ? (
          <SwipeVideo
            key={stream.href}
            streamHref={stream.href}
            coverImageHref={cover?.href}
            playerRef={playerRef}
          />
        ) : null}
      </AspectRatio>

      <View
        style={{
          position: 'absolute',
          width: '100%',
          maxWidth: (actualHeight / 16) * 9,
          paddingHorizontal: 32 + left + right,
          paddingBottom: 32 + bottom,
          bottom: 0,
          left: 0,
        }}
      >
        <VideoBottomControls
          playerRef={playerRef}
          allowSeek
          fixedTheme={false}
          coverSrc={cover?.href}
          title={title}
          description={description}
          bottom={0}
          lyrics={lyrics}
          actualHeight={actualHeight}
        />
      </View>

      <View
        style={{
          maxWidth: (actualHeight / 16) * 9,
          width: '100%',
          position: 'relative',
        }}
      >
        <Header />
      </View>
    </View>
  );
}

function Header() {
  const { top } = useSafeAreaInsets();
  const { canGoBack, goBack } = useNavigation();

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <Appbar.Header
        style={{
          zIndex: 1,
          maxWidth: 800,
          margin: 'auto',
          width: '100%',

          backgroundColor: 'transparent',
          elevation: 0,
          paddingHorizontal: 36,
          paddingLeft: 32,
          height: 52 + 36,
        }}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        <Appbar.BackAction
          size={24}
          color="#000"
          onPress={() => resetToPath('/favorites')}
          style={{
            borderRadius: 54 / 2,
            backgroundColor: '#FFFFFF',
            width: 42,
            height: 42,
            marginLeft: -2,
            marginRight: 16,
          }}
        />
      </Appbar.Header>
    </View>
  );
}
