/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { getStateFromPath, LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  DOMAIN,
  DOMAIN_BRANCH,
  DOMAIN_BRANCH_ALTERNATE,
  DOMAIN_NEXT,
} from '../config';
import { hasToken } from '../hooks/useToken';
import { BaseParamList } from '../types';

const REPLACEMENTS = {
  'http://localhost:3000/api': '!blossom!',
  'https://test.api.soundersmusic.com/api': '!bubbles!',
  'https://api.soundersmusic.com/api': '!buttercup!',
};

function parseUrl(url: string): string {
  const decoded = decodeURIComponent(url);
  if (decoded[0] !== '!') {
    return decoded;
  }

  let result = decoded;

  Object.keys(REPLACEMENTS).forEach((key) => {
    const value = REPLACEMENTS[key as keyof typeof REPLACEMENTS];
    if (result.startsWith(value)) {
      result = result.replace(value, key);
    }
  });

  return result;
}

export function prepareUrl(url: string): string {
  let result = url;

  Object.keys(REPLACEMENTS).forEach((key) => {
    const value = REPLACEMENTS[key as keyof typeof REPLACEMENTS];
    if (result.startsWith(key)) {
      result = result.replace(key, value);
    }
  });

  return encodeURIComponent(result);
}

const linking: LinkingOptions<BaseParamList> = {
  prefixes: [
    // Native (schema) url
    Linking.createURL('/'),

    // Bound app-links/universal link domain
    DOMAIN!,
    DOMAIN_NEXT!,
    DOMAIN_BRANCH!,
    DOMAIN_BRANCH_ALTERNATE!,
  ].filter(Boolean),
  config: {
    initialRouteName: 'Root',

    screens: {
      Root: {
        path: '',

        screens: {
          Home: {
            path: '',
            screens: {
              Discover: 'discover',
              Trending: 'trending',
              Favorites: 'favorites',
            },
          },

          RankingDetails: {
            path: 'events/:url/ranking/:details',
            parse: {
              url: parseUrl,
              details: parseUrl,
            },
            stringify: {
              url: prepareUrl,
              details: prepareUrl,
            },
          },

          Ranking: {
            path: 'events/:url/ranking',
            parse: {
              url: parseUrl,
            },
            stringify: {
              url: prepareUrl,
            },
          },

          Timeline: {
            path: 'gpvnl/timeline',
          },

          Event: {
            path: 'events/:url',
            parse: {
              url: parseUrl,
            },
            stringify: {
              url: prepareUrl,
            },
          },

          Player: {
            path: 'player/:url',
            parse: {
              url: parseUrl,
            },
            stringify: {
              url: prepareUrl,
            },
          },

          PhoneVerification: {
            path: 'verifications/:url',
            parse: {
              url: parseUrl,
            },
            stringify: {
              url: prepareUrl,
            },
          },

          // Settings
          Account: 'settings/account',
          TastingProfile: 'settings/profile',
          Settings: 'settings',
          About: 'about',
          Legal: 'legal',
          Preferences: 'getting-started/:section',

          //Tests
          TestConnection: 'tests/connection',
        },
      },

      // Logged out
      Onboarding: 'welcome',
      Landing: {
        path: 'landing/:section',
      },

      // Always
      SharedTrack: 'share/:id/:id2?/:id3?',

      // Catch all
      NotFound: '*',
    },
  },

  getStateFromPath: (path, options) => {
    console.debug('[getStateFromPath]', path);

    if (
      path.startsWith('/expo-auth-session') ||
      path.startsWith('expo-auth-session')
    ) {
      // A path with an # is currently not supported by this parser, but ? does
      // parse correctly. This code ensures only a # in the right place is
      // replaced, and not any arbitrary hash.
      if (
        path.startsWith('/expo-auth-session#') ||
        path.startsWith('expo-auth-session#')
      ) {
        path = path.replace('expo-auth-session#', 'expo-auth-session?');
      }

      // Sign in is the same as register, from an auth stand point
      return getStateFromPath(
        path.replace('expo-auth-session', 'landing/login'),
        options
      );
    }

    // Redirect to welcome if not logged in on root
    if (path === '/' && !hasToken()) {
      path = '/welcome';
    }

    /*
    if (path.startsWith('domain.for.auth')) {
      const index = path.indexOf('callback#');

      // Not a valid callback
      if (index === -1) {
        return getStateFromPath('landing/login', options);
      }

      const data = path.slice(index);

      // Sign in is the same as register, from an auth stand point
      return getStateFromPath(`landing/login?${data}`, options);
    }
    */

    return getStateFromPath(path, options);
  },
  getInitialURL() {
    // Handle in the app
    return undefined;
  },
  subscribe(listener) {
    // Do NOT subscript to URL changes, and handle these somewhere else
    return () => {};
  },
};

export const LINKING_CONFIG = linking.config;

export default linking;
