import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { Appbar, Button, Paragraph, Text, Title } from 'react-native-paper';
import Animated, {
  Extrapolate,
  interpolate,
  runOnJS,
  SharedValue,
  useAnimatedReaction,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withRepeat,
  withSpring,
  WithSpringConfig,
  withTiming,
} from 'react-native-reanimated';
import Svg, { Circle, Ellipse, G } from 'react-native-svg';
import { InvertedButton } from '../components/Button';
import { useLocale } from '../hooks/useLocale';
import { defineTranslations, i18n } from '../locale';
import { resetToPath } from '../navigation/utils';
import {
  BACKGROUND_DARK,
  DARK_PURPLE,
  PASTEL_BLUE,
  PASTEL_GREEN,
  PASTEL_PINK,
  PRIMARY_DARK,
} from '../theming';
import { useWindowDimensions } from '../utils/useLerpWidth';

defineTranslations({
  en: {
    app: {
      onboarding: {
        actions: {
          create_account: 'Create account',
          next: 'Next',
          skip: 'Skip',
          login: 'Sign in',
        },

        slides: {
          explore: {
            title: 'Explore new tracks',
            description:
              'Listen to unreleased tracks from upcoming artists in any genre.',
          },
          discover: {
            title: 'Discover new hits',
            description:
              'Check out what’s trending and discover the latest hits',
          },
          share: {
            title: 'Share with friends',
            description:
              'Rate tracks, check your personal favorites, and share them with friends',
          },
        },
      },
    },
  },

  nl: {
    app: {
      onboarding: {
        actions: {
          create_account: 'Registreer',
          next: 'Volgende',
          skip: 'Overslaan',
          login: 'Inloggen',
        },

        slides: {
          explore: {
            title: 'Ontdek muziek van opkomend talent',
            description:
              'Check nieuw talent in elk genre en geef je stem',
          },
          discover: {
            title: 'Met jouw stem wordt talent groot',
            description: 'Jij bepaalt mee wie er doorbreekt',
          },
          share: {
            title: 'Deel je favoriet met vrienden',
            description:
              'Geef je stem, bekijk de ranking en check je favorieten ',
          },
        },
      },
    },
  },
});

const SPRING_CONFIG: WithSpringConfig = {
  // ...SpringUtils.makeDefaultConfig(),
  damping: 40,
  mass: 1,
  stiffness: 300,
  overshootClamping: false,
};

export function OnboardingScreen() {
  const navigation = useNavigation();
  const skip = useCallback(() => {
    resetToPath('/landing/register');
  }, [navigation]);

  const { width } = useWindowDimensions();

  const snapTarget = useSharedValue<number>(0);
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, SPRING_CONFIG),
    [snapTarget]
  );

  const goto = useCallback((index: number) => {
    snapTarget.value = index;
  }, []);

  const locale = useLocale();
  const SLIDES = useMemo(
    () => [
      {
        title: i18n.translate('app.onboarding.slides.explore.title'),
        color: PASTEL_BLUE,
        description: i18n.translate(
          'app.onboarding.slides.explore.description'
        ),
      },
      {
        title: i18n.translate('app.onboarding.slides.discover.title'),
        color: PASTEL_PINK,
        description: i18n.translate(
          'app.onboarding.slides.discover.description'
        ),
      },
      {
        title: i18n.translate('app.onboarding.slides.share.title'),
        color: PASTEL_GREEN,
        description: i18n.translate('app.onboarding.slides.share.description'),
      },
    ],
    [locale]
  );

  const [activeSlide, setActiveSlide] = useState(0);

  // React to snapTarget changing
  useAnimatedReaction(
    () => snapTarget.value,
    (result, previous) => {
      if (result !== previous) {
        runOnJS(setActiveSlide)(result);
      }
    },
    [setActiveSlide]
  );

  const animatedStyle = useAnimatedStyle(() => {
    const value = current.value;
    const inputRange = [0, SLIDES.length];
    const outputRange = [0, -width * SLIDES.length];
    const interpolatedValue = interpolate(value, inputRange, outputRange);

    return {
      transform: [
        {
          translateX: interpolatedValue,
        },
      ],
    };
  });

  return (
    <View
      style={{
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        height: '100%',
        minHeight: 400,
        backgroundColor: DARK_PURPLE,
      }}
    >
      <StatusBar style="light" />
      <OnboardingAnimation snapTarget={snapTarget} count={SLIDES.length} />
      <Animated.View
        style={[
          animatedStyle,
          {
            width: width * SLIDES.length,
            flex: 1,
            flexDirection: 'row',
          },
        ]}
      >
        {SLIDES.map((slide, index, self) => {
          const isLast = index === self.length - 1;
          return (
            <OnboardingContext
              width={width}
              key={index}
              title={slide.title}
              color={slide.color}
              skip={skip}
              next={isLast ? skip : () => goto(index + 1)}
              isLast={isLast}
              isCurrent={activeSlide === index}
            >
              {slide.description}
            </OnboardingContext>
          );
        })}
      </Animated.View>
    </View>
  );
}

function OnboardingHeader() {
  return (
    <Appbar.Header
      style={{
        backgroundColor: 'transparent',
        elevation: 0,
        maxWidth: 600,
        alignSelf: 'center',
        width: '100%',
      }}
    >
      <Button
        mode="text"
        style={{
          marginLeft: 20,
        }}
        labelStyle={{
          color: '#FFFF',
          fontSize: 16,
          textAlign: 'left',
        }}
        onPress={() => resetToPath('/landing/login')}
        uppercase={false}
      >
        {i18n.translate('app.onboarding.actions.login')}
      </Button>
    </Appbar.Header>
  );
}

function OnboardingAnimation_({
  snapTarget,
  count,
}: {
  snapTarget: SharedValue<number>;
  count: number;
}) {
  const animation = useSharedValue(0);
  useEffect(() => {
    animation.value = withRepeat(
      withTiming(1, { duration: ANIMATION_DURATION * 1000 }),
      -1,
      false
    );
  }, []);

  return (
    <View style={{ width: '100%', flex: 1, maxHeight: 420 }}>
      <View
        style={{
          backgroundColor: BACKGROUND_DARK,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
      >
        <RegularBackground snapTarget={snapTarget} />
        <SpaceAnimation animation={animation} snapTarget={snapTarget} />
        <DiscoverAnimation animation={animation} snapTarget={snapTarget} />
        <FinalAnimation animation={animation} snapTarget={snapTarget} />
      </View>
      <View style={{ width: '100%', flex: 1 }}>
        <OnboardingHeader />
        <LinearGradient
          colors={[`${PRIMARY_DARK}00`, PRIMARY_DARK]}
          style={{
            height: 178,
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
        />
        <Slider snapTarget={snapTarget} count={count} />
      </View>
    </View>
  );
}

const OnboardingAnimation = React.memo(OnboardingAnimation_);

const BASE_OPACITY = 0.4;
const BASE_SCALE = 1.2;
const ANIMATION_DURATION = 10;

function SpaceAnimation({
  snapTarget,
  animation,
}: {
  snapTarget: SharedValue<number>;
  animation: SharedValue<number>;
}) {
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, SPRING_CONFIG),
    [snapTarget]
  );

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      current.value,
      [0, 0.5, 1],
      [1, 0, 0],
      Extrapolate.CLAMP
    ),
  }));

  const frontLayerOpacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animation.value, [0, 0.8, 1], [0, BASE_OPACITY, 0]),
  }));

  const backLayerOpacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animation.value, [0, 0.8, 1], [0, 0, BASE_OPACITY]),
  }));

  const backLayerZoomStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: interpolate(animation.value, [0, 0.8, 1], [0, BASE_OPACITY, 0]),
      },
    ],
  }));

  const frontLayerZoomStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: interpolate(
          animation.value,
          [0, 1],
          [BASE_SCALE, BASE_SCALE + 1]
        ),
      },
    ],
  }));

  const starOpacityFrontStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animation.value,
      [0, 0.2, 0.45, 0.7, 0.95, 1],
      [0.2, 1, 1, 0, 0, 0.2]
    ),
  }));

  const starOpacityBackStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animation.value,
      [0, 0.3, 0.6, 0.8, 1],
      [0.2, 0.2, 1, 1, 0.2]
    ),
  }));

  return (
    <Animated.View
      style={[
        opacityStyle,
        {
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        },
      ]}
    >
      <Animated.Image
        source={require('../../assets/background-onboarding-space.png')}
        style={[
          backLayerOpacityStyle,
          backLayerZoomStyle,
          {
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignSelf: 'center',
            maxWidth: 800,
          },
        ]}
        resizeMode="cover"
      />

      <Animated.Image
        source={require('../../assets/background-onboarding-space.png')}
        style={[
          frontLayerOpacityStyle,
          frontLayerZoomStyle,
          {
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignSelf: 'center',
            maxWidth: 800,
          },
        ]}
        resizeMode="cover"
      />

      <Animated.View
        style={[
          starOpacityBackStyle,
          {
            position: 'absolute',
            maxWidth: 503,
            width: '100%',
            height: 288,
            transform: [{ rotateZ: '-6deg' }],
          },
        ]}
      >
        <StarLayerTwo />
      </Animated.View>

      <Animated.Image
        source={require('../../assets/person-1.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0.3, 0.6, 0.8, 1],
              [1, 0, 0, 1]
            ),

            transform: [
              {
                scale: interpolate(
                  animation.value,
                  [0, 0.6, 0.8, 1],
                  [1, 1.4, 1 - 0.4 / 6, 1]
                ),
              },
              {
                translateX: interpolate(
                  animation.value,
                  [0, 0.6, 0.8, 1],
                  [-80, -110, -80 + 30 / 6, -80]
                ),
              },
              {
                translateY: interpolate(
                  animation.value,
                  [0, 0.6, 0.8, 1],
                  [100, 130, 100 - 30 / 6, 100]
                ),
              },
            ],
          })),
          {
            position: 'absolute',
            width: 140,
            height: 140,
            borderRadius: 100,
          },
        ]}
      />

      <Animated.Image
        source={require('../../assets/person-2.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0.1, 0.4, 0.8, 0.9],
              [0, 1, 1, 0],
              Extrapolate.CLAMP
            ),
            transform: [
              {
                scale: interpolate(animation.value, [0.2, 1], [1, 1.4]),
              },
              {
                translateX: interpolate(animation.value, [0.2, 1], [60, 80]),
              },
              {
                translateY: interpolate(animation.value, [0.2, 1], [50, 80]),
              },
            ],
          })),
          {
            position: 'absolute',
            width: 80,
            height: 80,
            borderRadius: 100,
          },
        ]}
      />

      <Animated.Image
        source={require('../../assets/person-3.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0, 0.3, 0.8, 0.9, 1],
              [1, 1, 0, 0, 1]
            ),

            transform: [
              {
                scale: interpolate(
                  animation.value,
                  [0, 0.8, 0.9, 1],
                  [1, 1.5, 1 - 0.5 / 8, 1]
                ),
              },
              {
                translateX: interpolate(
                  animation.value,
                  [0, 0.8, 0.9, 1],
                  [-50, -130, -50 + 80 / 8, -50]
                ),
              },
              {
                translateY: interpolate(
                  animation.value,
                  [0, 0.8, 0.9, 1],
                  [-50, -100, -50 + 50 / 8, -50]
                ),
              },
            ],
          })),
          {
            position: 'absolute',
            width: 100,
            height: 100,
            borderRadius: 100,
          },
        ]}
      />

      <Animated.Image
        source={require('../../assets/person-4.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0.3, 0.7, 0.9, 1],
              [0, 1, 1, 0],
              Extrapolate.CLAMP
            ),
            transform: [
              {
                scale: interpolate(animation.value, [0.2, 0.9], [1, 1.4]),
              },
              {
                translateX: interpolate(animation.value, [0.3, 1], [80, 110]),
              },
              {
                translateY: interpolate(animation.value, [0.3, 1], [-40, -80]),
              },
            ],
          })),
          {
            position: 'absolute',
            width: 60,
            height: 60,

            borderRadius: 100,
          },
        ]}
      />

      <Animated.View
        style={[
          starOpacityFrontStyle,
          {
            position: 'absolute',
            maxWidth: 1005,
            width: '100%',
            height: 433,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <StarLayerOne />
      </Animated.View>
    </Animated.View>
  );
}

function DiscoverAnimation({
  snapTarget,
  animation,
}: {
  snapTarget: SharedValue<number>;
  animation: SharedValue<number>;
}) {
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, SPRING_CONFIG),
    [snapTarget]
  );

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      current.value,
      [0, 1, 2],
      [0, 1, 0],
      Extrapolate.CLAMP
    ),
  }));

  const starOpacityFrontStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animation.value,
      [0, 0.2, 0.45, 0.7, 0.95, 1],
      [0.2, 1, 1, 0, 0, 0.2]
    ),
  }));

  const starOpacityBackStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animation.value,
      [0, 0.3, 0.6, 0.8, 1],
      [0, 0, 1, 1, 0]
    ),
  }));

  return (
    <Animated.View
      style={[
        opacityStyle,
        {
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          width: '100%',
          height: '100%',
        },
      ]}
    >
      <Animated.Image
        source={require('../../assets/person-4.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0, 0.46, 0.55, 0.57, 0.66, 1],
              [1, 1, 0, 0, 1, 1]
            ),
            transform: [
              {
                translateX:
                  interpolate(
                    animation.value,
                    [0, 0.55, 0.56, 0.57, 1],
                    [220, 180, 0, 260, 220]
                  ) - 50,
              },
            ],
          })),
          {
            position: 'absolute',
            top: 80,
            width: 100,
            height: 100,

            borderRadius: 200,
          },
        ]}
      />

      <Animated.Image
        source={require('../../assets/person-1.jpg')}
        resizeMode="contain"
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0, 0.4, 0.49, 0.51, 0.6, 1],
              [1, 1, 0, 0, 1, 1]
            ),
            transform: [
              {
                translateX:
                  interpolate(
                    animation.value,
                    [0, 0.49, 0.5, 0.51, 1],
                    [-60, -120, 0, 0, -60]
                  ) - 50,
              },
            ],
          })),
          {
            position: 'absolute',
            top: 130,
            width: 200,
            height: 200,

            borderRadius: 200,
          },
        ]}
      />

      <Animated.View
        style={[
          starOpacityBackStyle,
          {
            position: 'absolute',
            maxWidth: 503,
            width: '100%',
            height: 288,
          },
        ]}
      >
        {Platform.OS === 'web' ? (
          <StarLayerTwo />
        ) : (
          <Animated.View
            style={[
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useAnimatedStyle(() => ({
                transform: [
                  {
                    translateX: animation.value * -251 - 503,
                  },
                ],
              })),
              {
                flexDirection: 'row',
              },
            ]}
          >
            <StarLayerTwo />
            <StarLayerTwo />
            <StarLayerTwo />
          </Animated.View>
        )}
      </Animated.View>

      <Animated.View
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0, 0.42, 0.51, 0.53, 0.62, 1],
              [1, 1, 0, 0, 1, 1]
            ),
            transform: [
              {
                translateX:
                  interpolate(
                    animation.value,
                    [0, 0.51, 0.52, 0.53, 1],
                    [80, 20, 130, 130, 80]
                  ) - 50,
              },
            ],
          })),
          {
            position: 'absolute',
            top: 40,
          },
        ]}
      >
        <Animated.Image
          source={require('../../assets/person-3.jpg')}
          resizeMode="contain"
          style={{
            width: 130,
            height: 130,
            borderRadius: 130,
          }}
        />
        <Animated.View
          style={[
            useAnimatedStyle(() => ({
              transform: [
                {
                  scale: interpolate(
                    animation.value,
                    [0, 0.42, 0.51, 0.53, 0.62, 0.71, 1],
                    [1, 1, 1, 0, 0, 1, 1]
                  ),
                },
              ],
            })),
            {
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: 42,
              height: 42,
              borderRadius: 42 / 2,
              backgroundColor: '#FFC9C9',
              alignContent: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <Text
            style={{
              textAlign: 'center',
              color: '#BF7471',
              display: 'flex',
            }}
          >
            4
          </Text>
        </Animated.View>
      </Animated.View>

      <Animated.View
        style={[
          useAnimatedStyle(() => ({
            opacity: interpolate(
              animation.value,
              [0, 0.44, 0.53, 0.55, 0.64, 1],
              [1, 1, 0, 0, 1, 1]
            ),
            transform: [
              {
                translateX:
                  interpolate(
                    animation.value,
                    [0, 0.53, 0.54, 0.55, 1],
                    [150, 100, 200, 200, 150]
                  ) - 50,
              },
            ],
          })),
          {
            position: 'absolute',
            top: 180,
          },
        ]}
      >
        <Animated.Image
          source={require('../../assets/person-2.jpg')}
          resizeMode="contain"
          style={{
            width: 150,
            height: 150,
            borderRadius: 100,
          }}
        />

        <Animated.View
          style={[
            useAnimatedStyle(() => ({
              transform: [
                {
                  scale: interpolate(
                    animation.value,
                    [0, 0.44, 0.53, 0.55, 0.9, 0.99, 1],
                    [1, 1, 1, 0, 0, 1, 1]
                  ),
                },
              ],
            })),
            {
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: 42,
              height: 42,
              borderRadius: 42 / 2,
              backgroundColor: PASTEL_PINK,
              alignContent: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <Text
            style={{
              textAlign: 'center',
              color: '#9B6ABB',
              display: 'flex',
            }}
          >
            5
          </Text>
        </Animated.View>
      </Animated.View>

      <Animated.View
        style={[
          starOpacityFrontStyle,
          {
            position: 'absolute',
            maxWidth: 1005,
            width: '100%',
            height: 433,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <StarLayerOne />
      </Animated.View>
    </Animated.View>
  );
}

function FinalAnimation({
  snapTarget,
  animation,
}: {
  snapTarget: SharedValue<number>;
  animation: SharedValue<number>;
}) {
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, SPRING_CONFIG),
    [snapTarget]
  );

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(current.value, [1, 2], [0, 1], Extrapolate.CLAMP),
  }));

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animation.value,
      [0, 0.1, 0.9, 0.99, 1],
      [0, 1, 1, 0, 0]
    ),
    transform: [
      {
        scale: interpolate(animation.value, [0, 0.99, 1], [1, 1.3, 1]),
      },
    ],
  }));

  return (
    <Animated.View
      style={[
        opacityStyle,
        {
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          width: '100%',
          height: '100%',
        },
      ]}
    >
      <Animated.Image
        source={require('../../assets/person-1.jpg')}
        resizeMode="contain"
        style={[
          animatedStyle,
          {
            position: 'absolute',
            width: 200,
            height: 200,

            borderRadius: 100,
          },
        ]}
      />
    </Animated.View>
  );
}

function RegularBackground({
  snapTarget,
}: {
  snapTarget: SharedValue<number>;
}) {
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, SPRING_CONFIG),
    [snapTarget]
  );

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      current.value,
      [0, 0.5, 1, 2],
      [0, 0, 1, 1],
      Extrapolate.CLAMP
    ),
  }));

  return (
    <Animated.Image
      source={require('../../assets/background-onboarding.png')}
      resizeMode="cover"
      style={[
        animatedStyle,
        {
          position: 'absolute',
          left: '-10%',
          top: '-10%',
          width: '120%',
          height: '120%',
          transform: [
            {
              rotateZ: '180deg',
            },
          ],
        },
      ]}
    />
  );
}

function Slider({
  snapTarget,
  count,
}: {
  snapTarget: SharedValue<number>;
  count: number;
}) {
  const current = useDerivedValue(
    () => withSpring(snapTarget.value, { damping: 15, mass: 0.5 }),
    [snapTarget]
  );

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: current.value * 20,
      },
    ],
  }));

  return (
    <View
      style={{
        marginHorizontal: 'auto',
        alignSelf: 'center',
        maxWidth: 800,
        width: '100%',
        position: 'absolute',
        bottom: -48,
      }}
      pointerEvents="none"
    >
      <View
        style={{
          position: 'absolute',
          bottom: 32,
          height: 8,
          width: 68,
          marginHorizontal: 'auto',
          alignSelf: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {new Array(count + 1).fill(null).map((_, i) => (
          <View
            key={i}
            style={{
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: '#ffffff70',
            }}
          />
        ))}

        <Animated.View
          style={[
            animatedStyle,
            {
              width: 28,
              borderRadius: 8,
              height: 8,
              backgroundColor: '#fff',
              position: 'absolute',
            },
          ]}
        />
      </View>
    </View>
  );
}

function OnboardingContext({
  width,
  title,
  color,
  children,
  isLast,
  next,
  skip,
  isCurrent,
}: {
  width: number;
  title: string;
  color: string;
  children: string | number;
  isLast?: boolean;
  isCurrent: boolean;
  next: () => void;
  skip: () => void;
}) {
  return (
    <View style={{ width, justifyContent: 'space-evenly', maxHeight: 420 }}>
      <Text
        variant="headlineLarge"
        style={{
          color,
          textAlign: 'center',
          display: 'flex',
          marginHorizontal: 'auto',
        }}
      >
        {title}
      </Text>

      <Text
        variant="titleLarge"
        style={{
          color: '#FFFFFF',
          textAlign: 'center',
          maxWidth: 400,
          width: '100%',
          marginHorizontal: 'auto',
          display: 'flex',
        }}
      >
        {children}
      </Text>

      <View style={{ alignItems: 'center' }}>
        <InvertedButton
          disabled={!isCurrent}
          label={
            isLast
              ? i18n.translate('app.onboarding.actions.create_account')
              : i18n.translate('app.onboarding.actions.next')
          }
          onPress={next}
        />
        <View style={{ height: 20 }} />
        {!isLast && (
          <TextButton
            disabled={!isCurrent}
            label={i18n.translate('app.onboarding.actions.skip')}
            onPress={skip}
          />
        )}
      </View>
    </View>
  );
}

function TextButton({
  label,
  onPress,
  disabled,
}: {
  label: string;
  onPress: () => void;
  disabled?: boolean;
}) {
  return (
    <Button
      mode="text"
      theme={{ colors: { primary: '#FFFFFF7F' } }}
      onPress={onPress}
      uppercase={false}
      labelStyle={{
        textAlignVertical: 'center',
        includeFontPadding: false,
        minWidth: 80,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

function StarLayerOne() {
  return (
    <Svg viewBox="0 0 1005 433" style={{ width: 1005, height: 433 }}>
      <G>
        <G fill={PASTEL_BLUE} transform="translate(230.999)">
          <Circle
            cx={7.5}
            cy={7.5}
            r={7.5}
            transform="translate(39.001 257.001)"
          />
          <Circle
            cx={6.5}
            cy={6.5}
            r={6.5}
            transform="translate(462 303.001)"
          />
          <Circle cx={11.5} cy={11.5} r={11.5} transform="translate(352)" />
          <Circle
            cx={14.5}
            cy={14.5}
            r={14.5}
            transform="translate(536 129.001)"
          />
          <Ellipse
            cx={14}
            cy={14.5}
            rx={14}
            ry={14.5}
            transform="translate(594 301.001)"
          />
          <Circle cx={9} cy={9} r={9} transform="translate(116 267.001)" />
          <Circle cx={12} cy={12} r={12} transform="translate(117 373.001)" />
          <Circle cx={3} cy={3} r={3} transform="translate(438.001 37)" />
          <Circle cx={3} cy={3} r={3} transform="translate(418 267.001)" />
          <Circle cx={2} cy={2} r={2} transform="translate(140 163)" />
          <Ellipse
            cx={2.5}
            cy={2}
            rx={2.5}
            ry={2}
            transform="translate(191.001 197.001)"
          />
          <Circle cx={3} cy={3} r={3} transform="translate(43 214.001)" />
          <Circle cx={6} cy={6} r={6} transform="translate(182.001 183)" />
          <Circle cx={6} cy={6} r={6} transform="translate(59 352.001)" />
          <Circle cx={6} cy={6} r={6} transform="translate(256 367.001)" />
          <Circle cx={6} cy={6} r={6} transform="translate(61 211.001)" />
          <Circle cx={6} cy={6} r={6} transform="translate(23 388)" />
          <Circle cx={8} cy={8} r={8} transform="translate(365 174.002)" />
          <Circle cx={8} cy={8} r={8} transform="translate(0 16)" />
          <Circle cx={7} cy={7} r={7} transform="translate(245 93.001)" />
        </G>
        <G
          fill={PASTEL_PINK}
          opacity={0.5}
          transform="translate(220.265 20.165)"
        >
          <Circle cx={9} cy={9} r={9} transform="translate(528.734 -.166)" />
          <Circle cx={3} cy={3} r={3} transform="translate(531.734 186.834)" />
          <Circle cx={3} cy={3} r={3} transform="translate(388.734 279.834)" />
          <Circle cx={2} cy={2} r={2} transform="translate(316.735 210.834)" />
          <Circle cx={2} cy={2} r={2} transform="translate(258.734 245.834)" />
          <Circle cx={3} cy={3} r={3} transform="translate(337.734 257.834)" />
          <Circle cx={3} cy={3} r={3} transform="translate(.734 303.834)" />
          <Circle cx={3} cy={3} r={3} transform="translate(181.734 317.834)" />
          <Circle cx={3} cy={3} r={3} transform="translate(302.735 289.834)" />
          <Circle cx={6} cy={6} r={6} transform="translate(15.734 303.834)" />
          <Circle cx={7} cy={7} r={7} transform="translate(166.735 91.834)" />
        </G>
      </G>
    </Svg>
  );
}

function StarLayerTwo() {
  return (
    <Svg viewBox="0 0 503 288.001" style={{ width: 503, height: 288 }}>
      <G fill={PASTEL_GREEN} transform="translate(24 67)">
        <Ellipse
          cx={13.5}
          cy={13}
          rx={13.5}
          ry={13}
          transform="translate(213)"
        />
        <Circle cx={6} cy={6} r={6} transform="translate(174)" />
        <Ellipse
          cx={6.5}
          cy={6}
          rx={6.5}
          ry={6}
          transform="translate(120 110.001)"
        />
        <Ellipse
          cx={14}
          cy={14.5}
          rx={14}
          ry={14.5}
          transform="translate(451 16)"
        />
        <Circle cx={9} cy={9} r={9} transform="translate(0 134.001)" />
        <Circle cx={12} cy={12} r={12} transform="translate(174 176.001)" />
        <Circle cx={3} cy={3} r={3} transform="translate(121 151.001)" />
        <Circle cx={6} cy={6} r={6} transform="translate(434 78.001)" />
      </G>
      <G fill={PASTEL_BLUE} opacity={0.5}>
        <Circle cx={7.5} cy={7.5} r={7.5} transform="translate(394 182.001)" />
        <Circle cx={6.5} cy={6.5} r={6.5} transform="translate(105 215.001)" />
        <Circle cx={11.5} cy={11.5} r={11.5} transform="translate(175)" />
        <Circle cx={14.5} cy={14.5} r={14.5} transform="translate(57 90)" />
        <Ellipse
          cx={14}
          cy={14.5}
          rx={14}
          ry={14.5}
          transform="translate(0 210.001)"
        />
        <Circle cx={9} cy={9} r={9} transform="translate(347 189.001)" />
        <Circle cx={12} cy={12} r={12} transform="translate(341 261.001)" />
        <Circle cx={3} cy={3} r={3} transform="translate(128 37)" />
        <Circle cx={3} cy={3} r={3} transform="translate(142 191.001)" />
        <Circle cx={2} cy={2} r={2} transform="translate(343 117)" />
        <Ellipse
          cx={2.5}
          cy={2}
          rx={2.5}
          ry={2}
          transform="translate(306 141)"
        />
        <Circle cx={3} cy={3} r={3} transform="translate(399 153)" />
        <Circle cx={6} cy={6} r={6} transform="translate(306 130)" />
        <Circle cx={6} cy={6} r={6} transform="translate(377 240.001)" />
        <Circle cx={6} cy={6} r={6} transform="translate(253 255.001)" />
        <Circle cx={6} cy={6} r={6} transform="translate(375 150)" />
        <Circle cx={6} cy={6} r={6} transform="translate(413 276.001)" />
        <Circle cx={8} cy={8} r={8} transform="translate(172 123)" />
        <Circle cx={8} cy={8} r={8} transform="translate(432 16)" />
        <Circle cx={7} cy={7} r={7} transform="translate(259 66)" />
      </G>
    </Svg>
  );
}
