import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useURL } from 'expo-linking';
import { useEffect, useRef } from 'react';
import { Platform, View } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import { AccountScreen } from '../account/AccountScreen';
import { SettingsScreen } from '../account/SettingsScreen';
import { useSnackbar } from '../components/SnackbarProvider';
import { EventScreen } from '../events/EventScreen';
import { StandalonePlayer } from '../favorites/StandalonePlayer';
import { HomeScreen } from '../home/HomeScreen';
import { useToken } from '../hooks/useToken';
import { LandingScreen } from '../landing/LandingScreen';
import { RedirectToLanding } from '../landing/RedirectToLanding';
import { RedirectProvider } from '../landing/useRedirectBack';
import { defineTranslations, i18n } from '../locale';
import { OnboardingScreen } from '../onboarding/OnboardingScreen';
import { PreferencesScreen } from '../preferences/PreferencesScreen';
import { RankingDetailsScreen } from '../ranking/RankingDetailsScreen';
import { RankingScreen } from '../ranking/RankingScreen';
import { TimelineScreen } from '../timeline/TimelineScreen';
import { TastingProfileScreen } from '../sampling/TastingProfileScreen';
import { SharedTrackScreen } from '../sharing/SharedTrackScreen';
import NotFoundScreen from '../test/NotFoundScreen';
import { TestConnectionScreen } from '../test/TestConnectionScreen';
import { NAVIGATION_DARK_THEME, NAVIGATION_LIGHT_THEME } from '../theming';
import { BaseParamList, RootStackParamList } from '../types';
import { PhoneVerificationScreen } from '../verification/PhoneVerificationScreen';
import LinkingConfiguration from './LinkingConfiguration';
import { createMenuNavigator } from './MenuNavigator';
import { navigationRef, useNavigationReady } from './NavigationReadyContext';
import { resetToPath } from './utils';

export default function Navigation() {
  const { dark } = useTheme();

  const { hydrated } = useToken();
  const [, setReady] = useNavigationReady();

  const routeNameRef = useRef<string | undefined>();

  useEffect(() => {
    return () => {
      setReady(false);
    };
  }, [setReady]);

  if (!hydrated) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="small" />
      </View>
    );
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={dark ? NAVIGATION_DARK_THEME : NAVIGATION_LIGHT_THEME}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
        setReady(true);
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          // await Analytics.logEvent('screen_view', {
          //   screen_name: currentRouteName,
          // });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

defineTranslations({
  en: {
    app: {
      navigation: {
        see_shared_track_toast: 'Wil je de gedeelde track(s) bekijken?',
        see_shared_track_action: 'Bekijk',

        titles: {
          login: 'Login',
          register: 'Register',
          'forgot-password': 'Forgot password',
          shared_track: 'Shared track',
          not_found: 'Not found',

          account: 'Account',
          phone_verification: 'Verification',
          settings: 'Settings',
          tasting_profile: 'Your tasting profile',
        },
      },
    },
  },
});

function titleize(section: string) {
  const normalCase = section.replace(/-/g, ' ');
  return normalCase[0].toLocaleUpperCase() + normalCase.substring(1);
}

const Menu = createMenuNavigator<BaseParamList>();
const RootStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { token, hydrated } = useToken();
  const url = useURL();
  const { show } = useSnackbar();

  useEffect(() => {
    if (url?.includes('share') && Platform.OS !== 'web') {
      show(i18n.translate('app.navigation.see_shared_track_toast'), {
        label: i18n.translate('app.navigation.see_shared_track_action'),
        onPress: () => resetToPath(url),
        type: 'info',
      });
    }
  }, [url]);

  return (
    <RedirectProvider>
      <Menu.Navigator
        initialRouteName={token && hydrated ? 'Root' : 'Onboarding'}
      >
        {token && Platform.OS !== 'web' ? null : (
          <Menu.Screen
            name="Landing"
            component={LandingScreen}
            options={({ route }) => {
              if (route.params && route.params.section) {
                return {
                  title: `${i18n.translate(
                    `app.navigation.titles.${route.params.section}`
                  )} · Sounders Music Discovery`,
                };
              }
              return { title: 'Sounders Music Discovery' };
            }}
          />
        )}

        <Menu.Screen name="Root" component={LoggedInStack} />
        <Menu.Screen name="Onboarding" component={OnboardingScreen} />

        <Menu.Screen
          name="SharedTrack"
          component={SharedTrackScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.shared_track') +
              ' · Sounders Music Discovery',
          }}
        />

        <Menu.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.not_found') +
              ' · Sounders Music Discovery',
          }}
        />
      </Menu.Navigator>
    </RedirectProvider>
  );
}

function LoggedInStack() {
  const { token } = useToken();
  if (!token) {
    console.debug('[redirect] Redirecting to landing because no token found');
    return <RedirectToLanding />;
  }

  return (
    <RootStack.Navigator initialRouteName="Home">
      <RootStack.Group screenOptions={{ headerShown: false }}>
        <RootStack.Screen name="Home" component={HomeScreen} />
        <RootStack.Screen name="Event" component={EventScreen} />
        <RootStack.Screen name="Player" component={StandalonePlayer} />
        <RootStack.Screen
          name="PhoneVerification"
          component={PhoneVerificationScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.phone_verification') +
              ' · Sounders Music Discovery',
          }}
        />
        <RootStack.Screen
          name="Account"
          component={AccountScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.account') +
              ' · Sounders Music Discovery',
          }}
        />
        <RootStack.Screen
          name="TastingProfile"
          component={TastingProfileScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.tasting_profile') +
              ' · Sounders Music Discovery',
          }}
        />
        <RootStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            title:
              i18n.translate('app.navigation.titles.settings') +
              ' · Sounders Music Discovery',
          }}
        />
        <RootStack.Screen
          name="TestConnection"
          component={TestConnectionScreen}
          options={{ title: 'Test (connection) · Sounders Music Discovery' }}
        />

        <RootStack.Screen
          name="Ranking"
          component={RankingScreen}
          options={({ route }) => {
            return { title: 'Ranking · Sounders Music Discovery' };
          }}
        />
        <RootStack.Screen
          name="Timeline"
          component={TimelineScreen}
          options={({ route }) => {
            return { title: 'Timeline · Sounders Music Discovery' };
          }}
        />
      </RootStack.Group>
      <RootStack.Group
        screenOptions={{
          headerShown: false,
          presentation: 'modal',
          animation: Platform.select({
            ios: 'slide_from_bottom',
            android: 'fade_from_bottom',
            default: 'default',
          }),
        }}
      >
        <RootStack.Screen
          name="Preferences"
          component={PreferencesScreen}
          options={({ route }) => {
            if (route.params && route.params.section) {
              return {
                title: `${titleize(
                  route.params.section
                )} · Sounders Music Discovery`,
              };
            }
            return { title: 'Sounders Music Discovery' };
          }}
        />

        <RootStack.Screen
          name="RankingDetails"
          component={RankingDetailsScreen}
          options={({ route }) => {
            return { title: 'Ranking · Sounders Music Discovery' };
          }}
        />
      </RootStack.Group>
    </RootStack.Navigator>
  );
}

/*...Platform.select({
  android: TransitionPresets.ModalPresentationIOS,
  ios: TransitionPresets.ModalPresentationIOS,
  default: TransitionPresets.DefaultTransition,
}),
  */
