import {
  AVPlaybackStatus,
  ResizeMode,
  Video,
  VideoReadyForDisplayEvent,
} from 'expo-av';
import { useKeepAwake } from 'expo-keep-awake';
import React, {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Image,
  ImageRequireSource,
  Platform,
  StatusBar,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { Appbar, Text, useTheme } from 'react-native-paper';
import Animated, {
  abs,
  add,
  and,
  AnimatedStyleProp,
  atan,
  call,
  clockRunning,
  cond,
  cos,
  divide,
  Easing,
  eq,
  floor,
  greaterThan,
  interpolate as interpolateValue,
  interpolateNode as interpolate,
  lessThan,
  max,
  multiply,
  neq,
  not,
  onChange,
  or,
  pow,
  set,
  sin,
  spring,
  SpringUtils,
  sqrt,
  startClock,
  stopClock,
  sub,
  useAnimatedStyle,
  useCode,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import {
  useClock,
  usePanGestureHandler,
  useValue,
  useValues,
  withSpringTransition,
} from 'react-native-redash/lib/module/v1/index';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useIsMounted } from 'use-is-mounted';
import { AspectRatio } from '../components/AspectRatio';
import {
  ApiEventTrack,
  ApiTrackCoverImageVariants,
  ApiTrackStreamVariants,
} from '../events/useEventTracks';
import { defineTranslations, i18n } from '../locale';
import { MediaPlayer } from '../player/MediaPlayer';
import { DARK_PURPLE } from '../theming';
import { ApiTrackPosterImageVariants } from '../tracks/useTrack';
import { useWindowDimensions } from '../utils/useLerpWidth';
import { Background } from './Background';
import { BottomControls, VideoBottomControls } from './BottomControls';
import { Disc } from './Disc';
import { DropAreas } from './DropAreas';
import { Labels } from './Labels';

defineTranslations({
  en: {
    app: {
      swipe: {
        labels: {
          0: 'Not my thing',
          1: "It's alright",
          2: 'Decent',
          3: 'Like it',
          4: 'Love it',
          5: 'Amazing',
        },

        actions: {
          vote: 'Vote',
        },
      },
    },
  },

  nl: {
    app: {
      swipe: {
        labels: {
          0: 'Niet mijn ding',
          1: 'Het gaat',
          2: 'Klinkt ok',
          3: 'Leuk',
          4: 'Heel goed',
          5: 'Geweldig',
        },

        actions: {
          vote: 'Stem',
        },
      },
    },
  },

  /*nl: {
    app: {
      swipe: {
        labels: {
          0: 'Niets voor mij',
          1: 'Acceptabel',
          2: 'Gemiddeld',
          3: 'Leuk',
          4: 'Geweldig',
          5: 'Super',
        },
      },
    },
  },*/
});

const HIGHLIGHT_ANIMATION_TIME = 220;
const TAP_VOTE_DURATION = HIGHLIGHT_ANIMATION_TIME * 3;
const DROP_VOTE_DURATION = HIGHLIGHT_ANIMATION_TIME;

export function ResponsiveSwipeExperience({
  playerRef,
  fixedTheme,
  transparentTheme,
  track,
  total,
  completed,
  vertical = 0,
  allowSeek,
  vote,
  react,
  videoEnabled,
  brandingSrc,
  brandingSize,
  Header,
}: {
  playerRef: RefObject<MediaPlayer | null>;
  fixedTheme?: boolean;
  transparentTheme?: boolean;
  track: ApiEventTrack;
  completed: number | undefined;
  total: number | undefined;
  vertical?: number;
  allowSeek?: boolean;
  videoEnabled?: boolean;
  brandingSrc?: ImageRequireSource | null;
  brandingSize?: { width: number; height: number } | null;
  Header?: React.ComponentType | null;

  react(how: 'tap' | 'drag'): void;
  vote(rating: number): Promise<unknown>;
}) {
  useKeepAwake(undefined, { suppressDeactivateWarnings: true });

  const { width, height } = useWindowDimensions();
  const { left, bottom, right } = useSafeAreaInsets();

  const [votingEnabled, setVotingEnabled] = useState(false);

  const widthToUse = width;
  const heightToUse = height - vertical * 2;

  const limitedWidth = Math.min(widthToUse, 600);
  const limitedHeight = Math.min(1000, heightToUse);

  const limit =
    widthToUse / heightToUse > 375 / 812 ? limitedHeight : limitedWidth;
  const dimRatio = 375 / 812;

  const actualHeight =
    limit === limitedHeight ? limitedHeight : limitedWidth / dimRatio;
  const actualWidth =
    limit === limitedWidth ? limitedWidth : limitedHeight * dimRatio;

  const xRatio = actualWidth / 375;
  const yRatio = actualHeight / 812;
  const ratio = limit === limitedWidth ? xRatio : yRatio;

  const discSize = 290 * xRatio;
  const discTop = 230 * yRatio; // + (140 / 2) * yRatio; // (actualHeight - discSize) / 2;
  const discLeft = -110 * xRatio;

  const scale = useMemo(
    () => ({ x: xRatio, y: yRatio, limit: ratio }),
    [xRatio, yRatio, ratio]
  );

  const {
    track: {
      _links: { cover_image, cover, poster, wave, stream, artist },
      name: title,
      lyrics,
    },
  } = track;

  const description = artist?.name || '';

  const votingOpacity = useSharedValue(0);

  const enableVoting = useCallback(() => {
    setVotingEnabled(true);
    votingOpacity.value = withTiming(1, {
      duration: 200,
      easing: Easing.in(Easing.cubic),
    });
  }, [setVotingEnabled]);

  const disableVoting = useCallback(() => {
    votingOpacity.value = withTiming(0, {
      duration: 200,
      easing: Easing.out(Easing.cubic),
    });
    const i = setTimeout(() => setVotingEnabled(false), 250);
    return () => {
      clearTimeout(i);
    };
  }, [setVotingEnabled]);

  const votingStyle = useAnimatedStyle(() => {
    return {
      opacity: votingOpacity.value,
    };
  }, []);

  useEffect(() => {
    if (!videoEnabled) {
      enableVoting();
    }
  }, [videoEnabled]);

  const { top } = useSafeAreaInsets();
  const voteAndReset = useCallback(
    async (rating: number) => {
      if (videoEnabled) {
        votingOpacity.value = withTiming(0, {
          duration: 200,
          easing: Easing.out(Easing.cubic),
        });
      }

      return vote(rating).then(
        () => {
          setVotingEnabled(false);
        },
        (error) => {
          setVotingEnabled(false);
          return Promise.reject(error);
        }
      );
    },
    [vote, setVotingEnabled]
  );

  const coverSrc = (cover ?? cover_image)?.href;
  const posterSrc = (poster ?? cover ?? cover_image)?.href;

  return (
    <View
      style={{
        width: '100%',
        height: actualHeight,
        position: 'relative',
      }}
    >
      {stream?.href && videoEnabled ? (
        <AspectRatio
          style={{
            aspectRatio: 9 / 16,
            width: Math.min(limitedWidth, Math.floor((actualHeight / 16) * 9)),
            height: actualHeight,
            position: 'absolute',
            zIndex: 0,
          }}
        >
          <SwipeVideo
            key={stream.href}
            streamHref={stream.href}
            coverImageHref={coverSrc}
            posterImageHref={posterSrc}
            playerRef={playerRef}
          />
        </AspectRatio>
      ) : null}

      {videoEnabled ? (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: (actualHeight / 16) * 9,
            paddingHorizontal: 32 + left + right,
            paddingBottom: 32 + bottom,
            bottom: 0,
            left: 0,
          }}
        >
          {brandingSrc && brandingSize ? (
            <BrandingImage src={brandingSrc} size={brandingSize} />
          ) : null}

          <VideoBottomControls
            playerRef={playerRef}
            allowSeek={allowSeek}
            fixedTheme={fixedTheme}
            brandingSrc={brandingSrc}
            coverSrc={coverSrc}
            title={title}
            description={description}
            bottom={0}
            lyrics={lyrics}
            actualHeight={actualHeight}
          />
        </View>
      ) : null}

      {Header ? (
        <View
          style={{
            maxWidth: (actualHeight / 16) * 9,
            width: '100%',
            position: 'relative',
          }}
        >
          <Header />
        </View>
      ) : null}

      {videoEnabled && votingEnabled ? (
        <Animated.View
          style={[
            {
              width: (actualHeight / 16) * 9,
              height: '100%',
              backgroundColor: `${DARK_PURPLE}CC`,
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            },
            Platform.OS !== 'web' ? { opacity: 1 } : votingStyle,
          ]}
        />
      ) : videoEnabled ? (
        <TouchableWithoutFeedback onPress={enableVoting} style={{}}>
          <Animated.View
            style={{
              // backgroundColor: 'transparent',
              elevation: 0,
              width: discSize,
              height: discSize,
              left: discLeft,
              top: discTop,
              borderRadius: discSize / 2,
              position: 'absolute',
            }}
          >
            <BlinkingCircle
              from={0}
              to={0.1}
              initial={0.5}
              duration={5000}
              style={{
                left: 0,
                top: 0,
                width: discSize,
                height: discSize,
                borderRadius: discSize / 2,
                position: 'absolute',
                backgroundColor: '#fff',
              }}
            />

            <BlinkingCircle
              from={0.1}
              to={0.2}
              initial={0.1}
              duration={2500}
              style={{
                left: discSize / 4,
                top: discSize / 4,
                width: discSize / 2,
                height: discSize / 2,
                borderRadius: discSize / 4,
                position: 'absolute',
                backgroundColor: '#fff',
              }}
            />

            <View
              style={{
                left: (discSize / 4) * 1.5,
                top: (discSize / 4) * 1.5,
                width: discSize / 4,
                height: discSize / 4,
                borderRadius: discSize / 8,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BlinkingCircle
                from={0.3}
                to={0.4}
                initial={0.7}
                duration={1250}
                style={{
                  backgroundColor: '#FFF',
                  width: '100%',
                  height: '100%',
                  borderRadius: discSize / 8,
                  position: 'absolute',
                }}
              />

              <View
                style={{
                  width: '80%',
                  height: '80%',
                  borderRadius: discSize / 8,
                  position: 'absolute',
                  borderColor: '#FFF',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  margin: 'auto',
                }}
              />

              <Text
                variant="labelLarge"
                style={{
                  fontSize: 14,
                  color: '#FFF',
                  fontWeight: '500',
                  display: 'flex',
                }}
              >
                {i18n.translate('app.swipe.actions.vote')}
              </Text>
            </View>
          </Animated.View>
        </TouchableWithoutFeedback>
      ) : null}

      {votingEnabled || !videoEnabled ? (
        <Animated.View
          style={[
            {
              marginHorizontal: videoEnabled ? 0 : 'auto',
              alignSelf: videoEnabled ? 'auto' : 'center',
              width: actualWidth,
              height: actualHeight,
              marginTop: videoEnabled ? 0 : 0,
              position: 'relative',
              zIndex: 2,
            },

            Platform.OS !== 'web' ? { opacity: 1 } : votingStyle,
          ]}
        >
          <SwipeExperience
            playerRef={playerRef}
            discSize={discSize}
            width={actualWidth}
            height={actualHeight}
            discTop={discTop}
            discLeft={discLeft}
            scale={scale}
            coverImageHref={coverSrc || ''}
            waveHref={wave?.href}
            streamHref={stream?.href}
            react={react}
            vote={voteAndReset}
            fixedTheme={fixedTheme}
            transparentTheme={transparentTheme}
          />
        </Animated.View>
      ) : null}

      {votingEnabled && videoEnabled ? (
        <Animated.View
          style={[
            {
              maxWidth: (actualHeight / 16) * 9,
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 3,
            },

            Platform.OS !== 'web' ? { opacity: 1 } : votingStyle,
          ]}
        >
          <Appbar.Header
            style={{
              zIndex: 1,
              maxWidth: 800,
              margin: 'auto',
              width: '100%',
              backgroundColor: 'transparent',
              elevation: 0,
              paddingLeft: 32,
              height: 52 + 36,
            }}
            statusBarHeight={Platform.select({
              ios: StatusBar.currentHeight || top || 20,
              default: undefined,
            })}
          >
            <Appbar.Action
              size={24}
              icon="close"
              color="#000"
              onPress={disableVoting}
              style={{
                borderRadius: 54 / 2,
                backgroundColor: `#FFFFFF`,
                width: 42,
                height: 42,
                marginLeft: 'auto',
                marginRight: 16,
                position: 'absolute',
                right: 16,
              }}
            />
          </Appbar.Header>
        </Animated.View>
      ) : null}

      {videoEnabled ? null : (
        <View style={{ zIndex: 4 }}>
          <BottomControls
            playerRef={playerRef}
            completed={completed}
            total={total}
            allowSeek={allowSeek}
            fixedTheme={fixedTheme}
          />
        </View>
      )}
    </View>
  );
}

export function SwipeVideo({
  streamHref,
  coverImageHref,
  playerRef,
  posterImageHref,
}: {
  streamHref?: string;
  coverImageHref?: string;
  posterImageHref?: string;
  playerRef: RefObject<MediaPlayer | null>;
}) {
  const onLoadStart = useCallback(() => {
    const player = playerRef.current;
    // console.log({ player, videoRef });
    player?._mountVideo(videoRef.current!);
    player?._onLoadStart();
  }, [playerRef]);

  const onLoad = useCallback(
    (status: AVPlaybackStatus) => {
      playerRef.current?._onLoad(status);
    },
    [playerRef]
  );

  const onError = useCallback(
    (error: string) => {
      playerRef.current?._onError(error);
    },
    [playerRef]
  );

  const onReadyForDisplay = useCallback(
    (event: VideoReadyForDisplayEvent) => {
      playerRef.current?._onReadyForDisplay(event);
    },
    [playerRef]
  );

  const onPlaybackStatusUpdate = useCallback(
    (status: AVPlaybackStatus) => {
      playerRef.current?._onPlaybackStatusUpdate(status);
    },
    [playerRef]
  );

  const videoRef = useRef<Video>(null);

  return (
    <Video
      nativeID="video"
      ref={videoRef}
      useNativeControls={false}
      source={{
        uri:
          streamHref?.replace(
            /(%7[Bb]|{)variant(%7[Dd]|})/,
            '160b-1080:1920' satisfies ApiTrackStreamVariants
          ) || '',
        overrideFileExtensionAndroid: 'mp4',
      }}
      posterSource={{
        uri:
          posterImageHref?.replace(
            /{variant}|%7Bvariant%7D/,
            'portrait_2x' satisfies ApiTrackPosterImageVariants
          ) ??
          coverImageHref?.replace(
            /(%7[Bb]|{)variant(%7[Dd]|})/,
            'size_4x' satisfies ApiTrackCoverImageVariants
          ),
      }}
      shouldPlay
      resizeMode={ResizeMode.COVER}
      onPlaybackStatusUpdate={onPlaybackStatusUpdate}
      onLoadStart={onLoadStart}
      onLoad={onLoad}
      onError={onError}
      onReadyForDisplay={onReadyForDisplay}
      style={{
        backgroundColor: '#111',
        height: '100%',
        flex: 1,
        width: '100%',
        position: 'relative',
        borderRadius: 16,
      }}
      videoStyle={{
        position: Platform.select({
          web: 'relative',
          default: 'absolute',
        }),
      }}
    />
  );
}

function BrandingImage({
  src,
  size,
}: {
  src: ImageRequireSource;
  size: { width: number; height: number };
}) {
  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Image
        source={src}
        style={{
          ...size,
          marginHorizontal: 'auto',
          marginTop: 'auto',
        }}
        resizeMode="contain"
      />
    </View>
  );
}

function BlinkingCircle({
  style,
  from,
  to,
  initial,
  duration,
}: {
  style: AnimatedStyleProp<ViewStyle>;
  from: number;
  to: number;
  initial: number;
  duration: number;
}) {
  const animate = useSharedValue(initial);

  useEffect(() => {
    // Set the opacity value to animate between 0 and 1
    animate.value = withRepeat(
      withTiming(1, { duration, easing: Easing.ease }),
      -1,
      true
    );
  }, [duration]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: interpolateValue(animate.value, [0, 1], [from, to]),
    }),
    [animate, from, to]
  );

  return <Animated.View style={[style, animatedStyle]} />;
}

interface SwipeExperienceProps {
  playerRef: RefObject<MediaPlayer | null>;
  discSize: number;
  width: number;
  height: number;
  discTop: number;
  discLeft: number;
  scale: { x: number; y: number; limit: number };
  waveHref?: string;
  coverImageHref?: string;
  streamHref?: string;
  fixedTheme?: boolean;
  transparentTheme?: boolean;

  react(how: 'tap' | 'drag'): void;
  vote(rating: number): Promise<unknown>;
}

const config = {
  ...SpringUtils.makeDefaultConfig(),
  mass: 0.5,
  damping: 20,
};

function distance(
  x1: Animated.Node<number> | number,
  y1: Animated.Node<number> | number,
  x2: Animated.Node<number> | number,
  y2: Animated.Node<number> | number
) {
  //return (add(abs(sub(x1, x2)), abs(sub(y1, y2))))
  return abs(sqrt(add(pow(sub(x1, x2), 2), pow(sub(y1, y2), 2))));
}

function SwipeExperience({
  playerRef,
  waveHref,
  streamHref,
  discSize,
  discTop,
  discLeft,
  width,
  height,
  scale,
  coverImageHref,
  react,
  vote,
  fixedTheme,
  transparentTheme,
}: SwipeExperienceProps) {
  const { state, gestureHandler, translation, velocity, position } =
    usePanGestureHandler();

  const distanceToStart = useValue<number>(0);
  const closestX = useValue<number>(0);
  const closestY = useValue<number>(0);
  const canTapRef = useRef(true);
  const tapping = useValue<0 | 1>(0);
  const voting = useValue<0 | 1>(0);
  const active = useValue<number>(-1);
  const shrinking = useValue<0 | 1>(0);
  const isMountedRef = useIsMounted();

  const clockX = useClock();
  const [finishedX, finishedPositionX, finishedVelocityX, finishedTimeX] =
    useValues(1, 0, 0, 0);

  const clockY = useClock();
  const [finishedY, finishedPositionY, finishedVelocityY, finishedTimeY] =
    useValues(1, 0, 0, 0);

  const gestureAllowed = and(
    finishedX,
    finishedY,
    not(tapping),
    not(voting),
    not(shrinking)
  );
  const translateX = cond(gestureAllowed, translation.x, finishedPositionX);
  const translateY = cond(gestureAllowed, translation.y, finishedPositionY);

  const discScale = max(
    0,
    interpolate(
      withSpringTransition(
        cond(
          shrinking,
          2,
          and(
            neq(state, State.UNDETERMINED),
            neq(state, State.END),
            neq(state, State.CANCELLED)
          )
        ),
        {
          mass: 0.5,
          damping: 15,
          restSpeedThreshold: 0.01,
          restDisplacementThreshold: 0.01,
        }
      ),
      {
        inputRange: [0, 1, 2],
        outputRange: [1, 0.8, 0],
      }
    )
  );

  const onTap = useCallback(
    (value: number) => {
      if (!canTapRef.current) {
        return;
      }

      canTapRef.current = false;

      react('tap');

      tapping.setValue(1);
      active.setValue(value);

      setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }

        // Start voting
        shrinking.setValue(1);

        tapping.setValue(0);
        voting.setValue(1);

        // Reset animation
        active.setValue(-1);

        vote(value)
          .catch(() => {})
          .then(() => {
            if (!isMountedRef.current) {
              return;
            }

            // Restore voting
            setTimeout(() => {
              if (!isMountedRef.current) {
                return;
              }

              // Restore voting
              voting.setValue(0);
              shrinking.setValue(0);

              canTapRef.current = true;
            }, 230);
          });
      }, TAP_VOTE_DURATION);
    },
    [canTapRef, isMountedRef, vote]
  );

  const onDragDone = useCallback(
    (value: number) => {
      canTapRef.current = false;
      shrinking.setValue(1);

      // stop();

      setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }

        // Start voting
        tapping.setValue(0);
        voting.setValue(1);

        // Reset animation
        active.setValue(-1);

        // Reset values
        position.x.setValue(0);
        position.y.setValue(0);
        velocity.x.setValue(0);
        velocity.y.setValue(0);
        translation.x.setValue(0);
        translation.y.setValue(0);

        // Move back
        closestX.setValue(0);
        closestY.setValue(0);

        requestAnimationFrame(() => {
          if (!isMountedRef.current) {
            return;
          }

          vote(value)
            .catch(() => {})
            .then(() => {
              if (!isMountedRef.current) {
                return;
              }

              setTimeout(() => {
                if (!isMountedRef.current) {
                  return;
                }

                // Restore voting
                voting.setValue(0);
                shrinking.setValue(0);

                canTapRef.current = true;
              }, 230);
            });
        });
      }, DROP_VOTE_DURATION);
    },
    [canTapRef, isMountedRef, vote]
  );

  /*
  useEffect(() => {
    // Restore voting
    voting.setValue(0);
    shrinking.setValue(0);
  }, [waveHref]);
  */

  const discPositionStyle = {
    left: discLeft,
    top: discTop,
    width: discSize,
    height: discSize,
    borderRadius: discSize / 2,
  };

  const backgroundColor = useTheme().colors.surface; // '#fff';

  const radsPerDropArea = useValue((180 / 6 / 360) * Math.PI * 2);
  const dragAlphaLoose = useValue(-1);
  const dragAlpha = useValue(-1);
  const targetR = useValue((width / 4) * 3);

  useCode(
    () => [
      cond(eq(state, State.ACTIVE), [
        set(
          distanceToStart,
          distance(discLeft + discSize / 2, 0, translateX, translateY)
        ),

        cond(gestureAllowed, [
          cond(
            lessThan(distanceToStart, discSize / 2),
            [set(closestX, 0), set(closestY, 0)],
            [
              // todo get next snap
              set(closestX, 0),
              set(closestY, 0),
            ]
          ),

          set(
            active,
            cond(
              // Only update "drag and drop" area if sufficiently far from start
              and(
                greaterThan(translateX, 0),
                greaterThan(distanceToStart, discSize / 2)
              ),
              [
                set(
                  dragAlphaLoose,
                  atan(
                    divide(translateX, sub(discLeft + discSize / 2, translateY))
                  )
                ),

                // This goes from 0 -> 90 degrees and then from -90 -> -0 degrees
                //
                // The degrees is therefore equal to the positive value, or, when negative,
                // equal to 90 degrees + (90 - abs(negative value)).
                //
                set(
                  dragAlpha,
                  cond(
                    lessThan(dragAlphaLoose, 0),
                    add(Math.PI, dragAlphaLoose),
                    dragAlphaLoose
                  )
                ),

                floor(divide(dragAlpha, radsPerDropArea)),
              ],
              -1
            )
          ),
        ]),
      ]),

      onChange(state, [
        // Start spring
        cond(and(eq(state, State.END), gestureAllowed), [
          cond(
            neq(active, -1),
            [
              /*
              debug('theta', multiply(add(0.5, active), radsPerDropArea)),
              debug("a'", dragAlphaLoose),
              debug('a', dragAlpha),
              debug('x', translation.x),
              debug('y', translation.y),
              debug('scale-x', new Value(scale.x)),
              debug('scale-y', new Value(scale.y)),
              debug('r', radsPerDropArea),
              debug('tt', active),
              debug('w', new Value(width)),
              debug('h', new Value(height)),
              debug('r', targetR),
              debug(
                'tx',
                multiply(
                  targetR,
                  sin(multiply(add(0.5, active), radsPerDropArea))
                )
              ),
              debug(
                'ty',
                multiply(
                  -1,
                  targetR,
                  cos(multiply(add(0.5, active), radsPerDropArea))
                )
              ),
              debug(
                't',
                multiply(
                  add(0.5, active),
                  divide(radsPerDropArea, Math.PI),
                  180
                )
              ),
*/
              set(
                closestX,
                multiply(
                  targetR,
                  sin(multiply(add(0.5, active), radsPerDropArea))
                )
              ),
              set(
                closestY,
                multiply(
                  -1,
                  targetR,
                  cos(multiply(add(0.5, active), radsPerDropArea))
                )
              ),

              call([active], ([value]) => onDragDone(value)),
            ],
            [set(closestX, 0), set(closestY, 0)]
          ),

          set(finishedPositionX, translation.x),
          set(finishedVelocityX, velocity.x),
          set(finishedTimeX, 0),
          set(finishedX, 0),
          startClock(clockX),

          set(finishedPositionY, translation.y),
          set(finishedVelocityY, velocity.y),
          set(finishedTimeY, 0),
          set(finishedY, 0),
          startClock(clockY),
        ]),

        // Register reaction time
        cond(or(eq(state, State.BEGAN), eq(state, State.ACTIVE)), [
          call([], () => {
            react('drag');
          }),
        ]),

        // Cancel gesture
        cond(and(eq(state, State.BEGAN), not(gestureAllowed)), [
          set(state, State.CANCELLED),
        ]),

        // Cancel spring
        /* cond(eq(state, State.BEGAN), [
          debug('p', position.x),

          set(closestX, add(translation.x, position.x)),
          set(closestY, translation.y),

          set(finishedX, 1),
          set(finishedY, 1),
        ]),*/
      ]),

      // When spring ends, reset
      onChange(
        finishedX,
        cond(finishedX, [set(translation.x, closestX), stopClock(clockX)])
      ),

      onChange(
        finishedY,
        cond(finishedY, [set(translation.y, closestY), stopClock(clockY)])
      ),

      // Run the springs
      cond(clockRunning(clockX), [
        spring(
          clockX,
          {
            position: finishedPositionX,
            velocity: finishedVelocityX,
            finished: finishedX,
            time: finishedTimeX,
          },
          {
            ...config,
            toValue: closestX,
            restDisplacementThreshold: 0.1,
            restSpeedThreshold: 1,
          }
        ),
        set(translation.x, finishedPositionX),
      ]),

      cond(clockRunning(clockY), [
        spring(
          clockY,
          {
            position: finishedPositionY,
            velocity: finishedVelocityY,
            finished: finishedY,
            time: finishedTimeY,
          },
          {
            ...config,
            toValue: closestY,
            restDisplacementThreshold: 0.1,
            restSpeedThreshold: 1,
          }
        ),
        set(translation.y, finishedPositionY),
      ]),
    ],
    [react]
  );

  return (
    <Fragment>
      <View
        style={{
          width,
          height,
          position: 'relative',
        }}
      >
        <DropAreas
          width={width}
          height={height}
          active={active}
          transparentTheme={transparentTheme}
        />
        <Background
          width={width}
          height={height}
          transparentTheme={transparentTheme}
        />
        <Labels
          width={width}
          height={height}
          scale={scale.limit}
          scaleX={scale.x}
          scaleY={scale.y}
          active={active}
          tap={onTap}
          fixedTheme={fixedTheme}
          transparentTheme={transparentTheme}
        />

        <Animated.View
          style={[
            discPositionStyle,
            { position: 'absolute' },
            transparentTheme
              ? {
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: '#FFF',
                  backgroundColor: '',
                }
              : { backgroundColor },
          ]}
        />

        <PanGestureHandler {...gestureHandler}>
          <Animated.View
            style={[
              discPositionStyle,
              {
                opacity: cond(and(voting, shrinking), 0, 1),
                transform: [
                  { translateX },
                  { translateY },
                  { scale: discScale },
                ],
                ...(Platform.OS === 'web' ? { cursor: 'grab' } : {}),
              },
            ]}
          >
            <Disc
              coverImageHref={coverImageHref}
              scale={scale.limit}
              discSize={discSize}
              playerRef={playerRef}
            />
          </Animated.View>
        </PanGestureHandler>
      </View>
    </Fragment>
  );
}
