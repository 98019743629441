import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
// import { authorization } from '../utils/authorization';
import { Timeline } from './TimelineTypes';

export type TimelineResponse = Readonly<{
  data: { timeline: Timeline; id: string };
}>;

export function useTimeline({
  enabled = true,
  onError,
  ...options
}: UseQueryOptions<TimelineResponse, FetchMediaError | Error> = {}) {
  const { ref, token, logout } = useToken();

  const url = 'https://gpvnl-timeline-data.netlify.app/timeline.json';

  const locale = useLocale();

  return useQuery<TimelineResponse, FetchMediaError | Error>(
    [locale, url, 'index'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            'application/vnd.soundersmusic.countdown.v1.collection+json',
          ].join(', '),
          acceptLanguage: locale,
          // Don't leak authorization,
          // TODO: Restore when using API
          // authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (response && typeof response === 'object') {
        if ('data' in response) {
          return response as TimelineResponse;
        }

        throw new FetchMediaError(
          'Expected countdowns in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected countdowns response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
